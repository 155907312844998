import { useQuery } from "@apollo/client";
import { Button, Popover, Tooltip } from "@mui/material";
import { log } from "console";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import imgGetter from "../../assets/imgGetter";
import { getPartnerWarehouseQuery } from "../../gql/gql";
import { partnerSelector, setPartner } from "../../store/reducers/partner";
import "./sidebar.scss";

function Sidebar() {
  const { data } = useQuery(getPartnerWarehouseQuery);
  const dispatch = useDispatch();
  const partnersWarehouse = data?.partner_warehouses;
  const arrayCode = partnersWarehouse.map((item: any) => item.code);
  const publicAssets: string = process.env.PUBLIC_URL + "/assets";
  const location = useLocation();
  const [urlState, setUrlState] = useState<string>(location.pathname);
  const [openMenu, setOpenMenu] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const partner = useSelector(partnerSelector);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangePartnerWarehouse = (code: string) => {
    const partner = partnersWarehouse.find((item: any) => item.code === code);
    dispatch(setPartner(partner));
  };

  return (
    <>
      <button
        onClick={() => setOpenMenu((prev) => !prev)}
        className={`navBtn ${
          (openMenu && "open") || "close"
        } block bg-[#1f3d5e] px-2 rounded-r-md cursor-pointer md:hidden fixed z-50 top-0 left-0`}
      >
        <img src={imgGetter.icon.hamburger} width="44px" alt="" />
      </button>
      <aside
        className={`sidebar py-[10px] z-[1000] ${
          (openMenu && "open") || "close"
        }`}
      >
        <div className="brand flex gap-4 justify-center w-full">
          <div className="img-wrap rounded-full ">
            <img width="40px" src={publicAssets + "/logo/small.svg"} alt="" />
          </div>
        </div>
        <div className="sidebar-items py-[2rem]">
          <Link
            to="/"
            onClick={() => setUrlState("/")}
            className={`sidebar-item py-[1.5rem] flex flex-col items-center ${
              urlState === "/" && "active"
            }`}
          >
            <img
              width="30px"
              height="30px"
              src={imgGetter.icon.home.colorless}
              alt=""
            />
          </Link>
          <Link
            to="/whm"
            onClick={() => setUrlState("/whm")}
            className={`sidebar-item py-[1.5rem] flex flex-col items-center ${
              urlState === "/whm" && "active"
            }`}
          >
            <img width="30px" height="30px" src={imgGetter.icon.box} alt="" />
          </Link>
          <Tooltip title="Logout">
            <Button
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");
                // redirect to login
                window.location.href = "/login";
              }}
            >
              <img
                width="30px"
                height="30px"
                src={imgGetter.icon.close}
                alt="Logout"
              />
            </Button>
          </Tooltip>
        </div>

        <div className="switch-warehouse flex justify-center items-center">
          <Button aria-describedby={id} onClick={handleClick}>
            <img
              width="30px"
              height="30px"
              src={imgGetter.icon.switchWMS}
              alt="Switch WMS"
            />
          </Button>

          {/* Đổi kho */}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="switchWMS-wrap">
              <div className="title">
                <h3 className="font-bold">Chuyển kho</h3>
              </div>
              <div className="content">
                {arrayCode?.map((item: any) => {
                  return (
                    <div
                      key={item}
                      className={partner.code === item ? "item active" : "item"}
                      onClick={() => handleChangePartnerWarehouse(item)}
                    >
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </Popover>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
