import GetCurrentProfile from "./api/getCurrentProfile";
import FetchPartner from "./api/getPartner";
import FetchWarehouse from "./api/getWarehouse";
import "./App.css";
import SideBar from "./components/SideBar/sidebar";
import PageSection from "./pages/pageSection";

function App() {
  return (
    <div className="app">
      <GetCurrentProfile>
        <FetchPartner>
          <FetchWarehouse>
            <SideBar />
            <PageSection />
          </FetchWarehouse>
        </FetchPartner>
      </GetCurrentProfile>
    </div>
  );
}

export default App;
