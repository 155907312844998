import { useState } from "react";
import imgGetter from "../../assets/imgGetter";
import Option from "../Option";
import styles from "./styles.module.scss";

type propsType = {
  className?: String;
  searchValue?: any;
  setSearchValueInput?: any;
  searchValueInput?: any;
  handleSearch?: any;
};

function SearchBox(props: propsType) {
  const { searchValue, setSearchValueInput, searchValueInput, handleSearch } =
    props;
  const [searchFilter, setFilter] = useState(searchValue?.value[0] || "");

  let searchContent =
    searchValue.content[searchValue.value.indexOf(searchFilter)];

  return (
    <div className={props?.className + ""}>
      <div className="search-wrap flex relative">
        <img
          src={imgGetter.icon.search}
          className="absolute top-[10px] z-20 left-[16px]"
          width="27px"
          height="27px"
          alt=""
        />
        <input
          className={styles.input}
          type="text"
          placeholder={`Tìm kiếm ${searchContent.toLocaleLowerCase()}...`}
          value={searchValueInput}
          onChange={(e) => setSearchValueInput(e.target.value)}
        />
        <Option
          option={searchValue}
          setSelected={setFilter}
          styles={styles}
          caret={imgGetter.icon.caret.down.white}
        />
        <button
          className={styles.button}
          onClick={() => handleSearch(searchContent)}
        >
          Tìm
        </button>
      </div>
    </div>
  );
}

export default SearchBox;
