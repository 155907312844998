import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import store from "../src/store";
import client from "./api/";
import App from "./App";
import CheckLogin from "./components/CheckLogin/CheckLogin";
import "./index.css";
import Login from "./pages/Authentication/Login/Login";
import Home from "./pages/Home";
import Warehouse from "./pages/Warehouse";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <CheckLogin>
        <App />
      </CheckLogin>
    ),
    // errorElement: <Error404 />,
    children: [
      {
        path: "/whm",
        element: <Warehouse />,
      },
      {
        index: true,
        element: <Home />,
      },
    ],
  },
]);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <RouterProvider router={router} />
      </SnackbarProvider>
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
