import { gql, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { setWarehouseInfo } from "../store/reducers/warehouse";

export default function FetchWarehouse(props) {
  // Update
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const codePartner = partnerReducer?.partner?.code;
  const infoQuery = gql`
    query warehouseInfo($code: String!) {
      partner_warehouses(where: { code: { _eq: $code } }) {
        name
        code
        partner_id
        address
        status
        id
      }
    }
  `;

  const dispatch = useDispatch();

  let infoRes = useQuery(infoQuery, {
    variables: {
      code: codePartner,
    },
  });

  if (infoRes.loading) return <></>;

  dispatch(setWarehouseInfo(infoRes.data));

  return <>{props.children}</>;
}
