import styles from "./PageLoading.module.scss";
export interface PageLoadingProps {
  className?: string;
  isLoading: boolean;
  children: React.ReactNode;
}

const PageLoading = (props: PageLoadingProps) => {
  const { className, isLoading } = props;
  if (isLoading) {
    return (
      <div className={styles.root}>
        <svg
          className={styles.spinner}
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className={styles.path}
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  return <>{props.children}</>;
};

export default PageLoading;
