import { configureStore } from "@reduxjs/toolkit";
import currentPageReducer from "./reducers/currentPage";
import filterReducer from "./reducers/filterSlice";
import partnerReducer from "./reducers/partner";
import selectedItemsReducer from "./reducers/selectedItems";
import warehouseReducer from "./reducers/warehouse";
// Store
const store = configureStore({
  reducer: {
    filterReducer,
    currentPageReducer,
    warehouseReducer,
    partnerReducer,
    selectedItemsReducer,
  },
});

export default store;
