import { createSlice } from "@reduxjs/toolkit";

const partnerSplice = createSlice({
  name: "partner",
  initialState: {
    partner: {},
  },
  reducers: {
    setPartner: (state, action) => {
      const partner = action.payload;
    localStorage.setItem("partner", JSON.stringify(partner) )

      return { ...state, partner: { ...partner } };
    },
  },
});

// Reducer
const partnerReducer = partnerSplice.reducer;

// Selector
export const partnerSelector = (state) => state.partnerReducer.partner;

// Action export
export const { setPartner } = partnerSplice.actions;

export default partnerReducer;
