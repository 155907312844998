import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { icon } from "../../../assets/imgGetter";
import LinePanel from "../../../components/LinePanel/LinePanel";
import ShelfDetail from "../../../components/ShelfDetail";
import { partnerWarehouseSlotsQuery } from "../../../gql/gql";
import { GetSlotsQuery } from "../../../gql/types";
import { IFragment, ILine, IShelf } from "../../../interface/data";
import {
  deleteLine,
  detailShelfClickSelector,
} from "../../../store/reducers/warehouse";
import styles from "./styles.module.scss";

const whIcon = icon.wh;

type propsType = {
  lineCode: string;
  setLine?: any;
  lines: any;
  setLines?: any;
};
function LineInfo(props: propsType) {
  const { lineCode, setLine, lines, setLines } = props;
  // Call API to find lines info here!
  const detailShelfClick: IShelf = useSelector(detailShelfClickSelector);

  const line = lines.find((line: ILine) => line.code === lineCode) || lines[0];

  const dispatch = useDispatch();

  let editLine: ILine = line ? JSON.parse(JSON.stringify(line)) : {};

  function deleteLineHandler() {
    setTimeout(() => {
      dispatch(deleteLine(lineCode));
      setLine("");
    }, 500);
  }

  // For toggle the setitng panel
  const [isOpenPanel, setOpenPanel] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [indexSelected, setIndexSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const totalSlotInFragments = (fragments: IFragment) => {
    let total = fragments.shelves.map((shelf) => {
      return shelf.capacity;
    });
    return total.flat().reduce((partialSum, a) => partialSum + a, 0);
  };

  const handleClickShelf = (index: number) => {
    setIndexSelected(index);
  };

  const totalFragmentInWarehouse = () => {
    let total = 0;
    lines.forEach((line: ILine) => {
      total += line.fragments.length;
    });
    return total;
  };

  const totalShelfInWarehouse = () => {
    let total = 0;
    lines.forEach((line: ILine) => {
      line.fragments.forEach((fragment: IFragment) => {
        total += fragment.shelves.length;
      });
    });
    return total;
  };

  const [slotEmpty, setSlotEmpty] = useState(0);
  const [slotFull, setSlotFull] = useState(0);
  const [slotPick, setSlotPick] = useState(0);

  const [getDataSlots] = useLazyQuery(partnerWarehouseSlotsQuery);
  const statusSlotShelf = (shelf: IShelf): any => {
    getDataSlots({
      variables: {
        shelfId: shelf?.id,
      },
      onCompleted: (data: GetSlotsQuery) => {
        const dataSlots = data.partner_warehouse_slots;
        const slotEmpty = dataSlots.filter((slot) => slot.status === 1).length;
        const slotFull = dataSlots.filter((slot) => slot.status === 2).length;
        const slotPick = dataSlots.filter(
          (slot) => slot.status === 3 || slot.status === 4
        ).length;
        setSlotEmpty(slotEmpty);
        setSlotFull(slotFull);
        setSlotPick(slotPick);
      },
    });
  };

  useEffect(() => {
    if (detailShelfClick) {
      statusSlotShelf(detailShelfClick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailShelfClick]);

  return lineCode === "" ? (
    <div className={"min-w-[35%] flex-col flex gap-8 " + styles.infoWrap}>
      <h1 className={styles.title}>Thông tin tổng kho</h1>
      <div className={styles.subtitle}>
        Kho hàng REshare : Chung cư 4s Linh Đông
      </div>
      <div className={styles.whInfo}>
        <div className={styles.title}>Tình trạng kho</div>
        <div>
          <div className={styles.em}>Tổng kệ đồ : </div>{" "}
          {totalFragmentInWarehouse()}
        </div>
        <div>
          <div className={styles.em}>Tổng sào : </div> {totalShelfInWarehouse()}
        </div>
      </div>

      {detailShelfClick && (
        <div className={styles.whInfo}>
          <div className={styles.title}>
            Tình trạng sào {detailShelfClick?.name?.replace("Kệ", "")}
          </div>
          <div>
            <div className={styles.em}>Tổng số móc :</div>{" "}
            {detailShelfClick.capacity || 0}
          </div>
          <div>
            <div className={styles.em}>Số sào trống: </div> {slotEmpty || 0}
          </div>

          <div>
            <div className={styles.em}>Số sào sẵn có:</div> {slotFull || 0}
          </div>

          <div>
            <div className={styles.em}>Số sào đã đặt hàng:</div> {slotPick || 0}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="min-w-[35%] flex-col flex gap-8">
      <div className={styles.subtitle} onClick={() => setOpenPanel(true)}>
        <p>{line.code}</p> <img src={icon.settings} className="h-full" alt="" />
      </div>
      {isOpenPanel && (
        <LinePanel
          line={editLine}
          deleteLineHandler={deleteLineHandler}
          setClose={setOpenPanel}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setLines={setLines}
        />
      )}
      {showDetail && (
        <ShelfDetail
          line={line}
          index={indexSelected}
          closeHandler={(state: boolean) => setShowDetail(state)}
        />
      )}
      <div
        className={
          "linesContainer flex flex-col gap-4 max-h-[60vh] overflow-scroll " +
          styles.infoFragWrap
        }
      >
        {line.fragments.map((frag: IFragment, i: any) => (
          <div
            key={i}
            className={styles.lineInfo}
            onClick={() => {
              setShowDetail(true);
              handleClickShelf(i);
            }}
          >
            <div className={styles.title}>
              Phân kệ {frag.code?.split("-")[1]?.replace("F", "")}
            </div>
            {/* TICH HOP */}
            <div className="flex flex-wrap gap-6">
              <div className="flex gap-4">
                <img src={whIcon.total} alt="" />
                <div className="flex flex-col">
                  <div className={styles.em}>Dung tích</div>
                  <div>{totalSlotInFragments(frag)}</div>
                </div>
              </div>
              <div className="flex gap-4">
                <img src={whIcon.used} alt="" />
                <div className="flex flex-col">
                  <div className={styles.em}>Đã dùng</div>
                  <div>{0}</div>
                </div>
              </div>
              <div className="flex gap-4">
                <img src={whIcon.status.allocated} alt="" />
                <div className="flex flex-col">
                  <div className={styles.em}>Trạng thái</div>
                  <div>Có hàng Pickup</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LineInfo;
