import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const token = localStorage.getItem("token");

const headerOpt = token ? { authorization: `Bearer ${token}` } : {};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...headerOpt,
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(
    createHttpLink({
      uri: "https://wms.reshare.vn/v1/graphql",
    })
  ),
  cache: new InMemoryCache(),
});
export default client;
