import { useMutation } from "@apollo/client";
import { Checkbox, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { icon } from "../../../../assets/imgGetter";
import PkgComponent from "../../../../components/PkgComp";
import { packProductsQuery } from "../../../../gql/gql";
import styles from "./ModalCheckPickupTest.module.scss";

export interface ModalCheckPickupTestProps {
  setOpen: any;
  open: boolean;
  orderSelectedData: any;
  orderDataState: any;
  setLines: any;
  setOrderIDPickState: any;
  setOrderIDPackState: any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "80%",
    md: "80%",
    lg: "60%",
    xl: "50%",
  },
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
};
const ModalCheckPickupTest = (props: ModalCheckPickupTestProps) => {
  const {
    open,
    setOpen,
    orderSelectedData,
    orderDataState,
    setLines,
    setOrderIDPickState,
    setOrderIDPackState,
  } = props;
  const [optionValue, setOptionValue] = useState([]);
  const [packProducts] = useMutation(packProductsQuery);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOptionValue([]);
    setOpen(false);
  };

  const slotInOrder = orderDataState
    ?.map((item: any) => {
      return item?.partner_warehouse_groups?.map((item: any) => {
        return item?.partner_warehouse_groups?.map((item: any) => {
          return item?.partner_warehouse_slots?.map((item: any) => {
            return item?.code;
          });
        });
      });
    })
    ?.flat(Infinity);

  const checkCondition = (slotInOrder: any, optionValue: any) => {
    if (slotInOrder?.length === optionValue?.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (checkCondition(slotInOrder, optionValue)) {
      await packProducts({
        variables: {
          order_id: orderSelectedData?.order_id,
          slot_codes: optionValue,
        },
        onCompleted(data) {
          enqueueSnackbar("Pack successfully", {
            variant: "success",
          });
          setOrderIDPickState();
          setOrderIDPackState();
          setLines();
          handleClose();
          setOptionValue([]);
          setLoading(false);
        },

        onError(err) {
          setLoading(false);

          enqueueSnackbar(err.message, {
            variant: "error",
          });
        },
      });
    } else {
      setLoading(false);

      enqueueSnackbar("Please select all slots", {
        variant: "error",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.packProductsWrap}>
          <div className={styles.header}>
            <PkgComponent
              className="grow"
              status={orderSelectedData?.status}
              ID={orderSelectedData?.order_id}
            />
          </div>
          <div className={styles.body}>
            <div className={styles.bodyLeft}>
              <div className={styles.bodyLeftHeader}>
                <h3 className={styles.title}>Các sào trong đơn hàng</h3>
              </div>
              <div className={styles.bodyLeftBody}>
                <div className={styles.bodyLeftBodyItem}>
                  {slotInOrder?.map((item: any) => {
                    return (
                      <div className="flex items-center my-[.5em]">
                        <div className={styles.statusNotify}>
                          <div className={styles.status}></div>
                          <img
                            src={icon.slotStatus.needPickup}
                            alt="needPickup"
                            title="needPickup"
                          />
                        </div>
                        <div className={styles.slotCode}>
                          <p className="ml-4 min-w-[100%] text-3xl">{item}</p>
                        </div>
                        <div className="input-wrap w-[15%] flex items-center h-[100%]"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.bodyRight}>
              <table className={styles.table}>
                <thead className={styles.bodyRightHeader}>
                  <tr>
                    <th className={styles.th}>
                      <h3 className={styles.title}>Các sào đã được pickup</h3>
                    </th>
                    <th className={styles.th}>
                      <h3 className={styles.title}>Đóng gói?</h3>
                    </th>
                  </tr>
                </thead>
                <tbody className={styles.bodyRightBody}>
                  {slotInOrder?.map((slotID: never) => {
                    return (
                      <tr className={styles.bodyRightBodyItem}>
                        <td className={styles.td}>
                          <div className="flex items-center ">
                            <div className={styles.statusNotify}>
                              <div className={styles.status}></div>
                              <img
                                src={icon.slotStatus.needPickup}
                                alt="needPickup"
                                title="needPickup"
                              />
                            </div>
                            <div className={styles.slotCode}>
                              <p className="ml-4 min-w-[100%] text-3xl">
                                {slotID}
                              </p>
                            </div>
                            <div className="input-wrap w-[15%] flex items-center h-[100%]"></div>
                          </div>
                        </td>
                        <td className={styles.td}>
                          <Checkbox
                            checked={optionValue?.includes(slotID)}
                            onChange={() => {
                              setOptionValue((prev: any) => {
                                if (prev.includes(slotID)) {
                                  return prev.filter(
                                    (item: any) => item !== slotID
                                  );
                                } else {
                                  return [...prev, slotID];
                                }
                              });
                            }}
                            classes={{ root: styles.checkbox }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.status}>
            <h3 className={styles.title}>
              Trạng thái đơn hàng:
              {checkCondition(slotInOrder, optionValue) ? (
                <span className={styles.textSafetyWarning}> Đủ điều kiện</span>
              ) : (
                <span className={styles.textAlert}> Chưa đủ điều kiện</span>
              )}
            </h3>

            <p className={styles.totalOrder}>
              Số sào trong đơn hàng:{" "}
              <span className={styles.numberHightline}>
                {slotInOrder?.length}
              </span>
            </p>
            <p className={styles.totalOrderPickup}>
              Số sào đã được đóng gói:{" "}
              <span className={styles.numberHightline}>
                {optionValue?.length}
              </span>
            </p>
          </div>

          <div className={styles.footer}>
            <div className={styles.actionWrap}>
              <button
                className={`${styles.action} ${styles.cancel}`}
                onClick={handleClose}
              >
                Huỷ bỏ
              </button>
              <button
                className={`${styles.action} ${
                  checkCondition(slotInOrder, optionValue)
                    ? styles.confirm
                    : styles.unConfirm
                }`}
                onClick={handleConfirm}
                disabled={loading}
              >
                Xác nhận đóng gói hoàn tất
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalCheckPickupTest;
