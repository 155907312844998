import { gql } from "@apollo/client";

export const loginQuery = gql`
  query Login($req: LoginInput!) {
    login(req: $req) {
      token
      refreshToken
    }
  }
`;

export const getPartnerWarehouseQuery = gql`
  query getPartnerWarehouse {
    partner_warehouses {
      code
      address
      id
      deleted_at
      created_at
      name
      partner_id
    }
  }
`;

export const partnerWarehouseSlotsQuery = gql`
  query getSlots($shelfId: String!) {
    partner_warehouse_slots(
      order_by: { order_number: asc }
      where: { partner_warehouse_group_id: { _eq: $shelfId } }
    ) {
      id
      order_number
      code
      status
      updated_at
    }
  }
`;

export const partnerWarehouseGroupsQuery = gql`
 query partnerWarehouseGroups($warehouseCode: String!) {
  line: partner_warehouse_groups(where: {warehouse_group_type_id: {_eq: "line"}, deleted_at: {_is_null: true}, partner_warehouse: {code: {_eq: $warehouseCode}}}, order_by: {order_number: asc}) {
    code
    id
    orderNumber: order_number
    capacity: slot
    warehouse_group_type_id
    name
    deleteAt: deleted_at
    fragments: partner_warehouse_groups(where: {warehouse_group_type_id: {_eq: "fragment"}, deleted_at: {_is_null: true}, partner_warehouse: {code: {_eq: $warehouseCode}}}, order_by: {order_number: asc}) {
      code
      name
      id
      name
      deleteAt: deleted_at
      orderNumber: order_number
      warehouse_group_type_id
      capacity: slot
      shelves: partner_warehouse_groups(where: {warehouse_group_type_id: {_eq: "shelf"}, deleted_at: {_is_null: true}, partner_warehouse: {code: {_eq: $warehouseCode}}}, order_by: {order_number: asc}) {
        id
        code
        name
        orderNumber: order_number
        warehouse_group_type_id
        deleteAt: deleted_at
        capacity: slot
        status
      }
    }
  }
}
`;

export const addGroupQuery = gql`
  mutation addGroupQuery(
    $code: String!
    $name: String!
    $order_number: Int!
    $partner_warehouse_id: String!
    $parent_partner_warehouse_group_id: String!
    $slot: Int!
    $status: String!
    $warehouse_group_type_id: String!
  ) {
    createPartnerWarehouseGroup(
      code: $code
      name: $name
      order_number: $order_number
      partner_warehouse_id: $partner_warehouse_id
      slot: $slot
      parent_partner_warehouse_group_id: $parent_partner_warehouse_group_id
      status: $status
      warehouse_group_type_id: $warehouse_group_type_id
    ) {
      id
    }
  }
`;

export const createPartnerWarehouseSlotsQuery = gql`
  mutation bulkCreateSlots(
    $codes: [String]!
    $partner_warehouse_group_id: String!
    $partner_warehouse_id: String!
  ) {
    bulkCreateSlots(
      codes: $codes
      partner_warehouse_group_id: $partner_warehouse_group_id
      partner_warehouse_id: $partner_warehouse_id
    ) {
      ids
    }
  }
`;

export const editGroupQuery = gql`
  mutation editGroup(
    $code: String!
    $name: String!
    $id: String!
    $order_number: Int!
    $partner_warehouse_id: String!
    $parent_partner_warehouse_group_id: String!
    $slot: Int!
    $status: String!
    $warehouse_group_type_id: String!
  ) {
    editPartnerWarehouseGroup(
      code: $code
      name: $name
      id: $id
      order_number: $order_number
      partner_warehouse_id: $partner_warehouse_id
      slot: $slot
      parent_partner_warehouse_group_id: $parent_partner_warehouse_group_id
      status: $status
      warehouse_group_type_id: $warehouse_group_type_id
    ) {
      id
    }
  }
`;

export const deleteGroupQuery = gql`
  mutation deleteGroup($id: String!) {
    deletePartnerWarehouseGroup(id: $id) {
      id
    }
  }
`;

export const getOrderIdPickQuery = gql`
  query getOrderIdPick {
    partner_products(
      distinct_on: order_id
      where: { partner_warehouse_slots: { status: { _eq: "3" } } }
    ) {
      order_id
    }
  }
`;

export const getOrderIdPackQuery = gql`
  query getOrderIdPack {
    partner_products(
      distinct_on: order_id
      where: { partner_warehouse_slots: { status: { _eq: "4" } } }
    ) {
      order_id
    }
  }
`;

export const pickupProductQuery = gql`
  mutation pickupProduct($order_id: String!, $slot_codes: [String]!) {
    pickupProduct(order_id: $order_id, slot_codes: $slot_codes) {
      slot_codes
    }
  }
`;

export const dataOrderQuery = gql`
  query dataOrder($orderId: String!) {
    partner_warehouse_groups(
      where: {
        deleted_at: { _is_null: true }
        warehouse_group_type_id: { _eq: "line" }
        partner_warehouse_groups: {
          partner_warehouse_groups: {
            partner_warehouse_slots: {
              partner_product: { order_id: { _eq: $orderId } }
            }
          }
        }
      }
    ) {
      code
      order_number
      warehouse_group_type_id
      id
      partner_warehouse_groups(
        where: {
          deleted_at: { _is_null: true }
          partner_warehouse_groups: {
            partner_warehouse_slots: {
              partner_product: { order_id: { _eq: $orderId } }
            }
          }
        }
      ) {
        code
        name
        order_number
        id
        warehouse_group_type_id
        partner_warehouse_groups(where: { deleted_at: { _is_null: true } }) {
          code
          order_number
          id
          warehouse_group_type_id
          partner_warehouse_slots(
            where: { partner_product: { order_id: { _eq: $orderId } } }
          ) {
            code
            name
            id
            order_number
            status
            partner_product {
              code
              order_id
              name
            }
          }
        }
      }
    }
  }
`;

export const packProductsQuery = gql`
  mutation packProducts($order_id: String!, $slot_codes: [String]!) {
    packProducts(order_id: $order_id, slot_codes: $slot_codes) {
      slot_codes
    }
  }
`;

export const getDataSlotQuery = gql`
  query getDataSlot($status: smallint!, $warehouseId: String!) {
    partner_warehouse_slots_aggregate(where: {status: {_eq: $status}, partner_warehouse_id: {_eq: $warehouseId}}) {
      aggregate {
        count
      }
    }
  }
`;

export const totalOrderQuery = gql`
  query totalOrder {
    partner_products_aggregate(distinct_on: order_id) {
      aggregate {
        count
      }
    }
  }
`;

export const totalOrderStatusQuery = gql`
  query totalOrderStatus($status: smallint!) {
    partner_products_aggregate(
      distinct_on: order_id
      where: { partner_warehouse_slots: { status: { _eq: $status } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const totalLineQuery = gql`
query totalLine($warehouseId: String!) {
  partner_warehouse_groups_aggregate(where: {warehouse_group_type_id: {_eq: "line"}, partner_warehouse_id: {_eq: $warehouseId}}) {
    aggregate {
      count
    }
  }
}
`;
