import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "mapFilter",
  initialState: {
    mapFilter: [],
  },
  reducers: {
    addFilter: (state, action) => {
      state.mapFilter.unshift(action.payload);
    },
    removeFilter: (state, action) => {
      console.log(action.payload);
      state.mapFilter = state.mapFilter.filter((f) => f !== action.payload);
    },
    addAllFilter: (state, action) => {
      state.mapFilter = [...action.payload];
    },
    removeAllFilter: (state, action) => {
      state.mapFilter = [];
    },
  },
});

// Reducer
const filterReducer = filterSlice.reducer;

// Selector
export const filterSelector = (state) => state.filterReducer.mapFilter;

// Action export
export const { addFilter, removeFilter, removeAllFilter, addAllFilter } =
  filterSlice.actions;
export default filterReducer;
