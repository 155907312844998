import { useState } from "react";
import { icon } from "../../../assets/imgGetter";
import { IFragment, IShelf } from "../../../interface/data";
import ValueConfigModal from "../../ValueConfigModal";
import styles from "../styles.module.scss";

interface ShelfModuleData {
  i: any;
  j: any;
  shelf: IShelf;
  changeShelfId: any;
  deleteShelf: any;
  changeShelfCapacity: any;
  frag: IFragment;
}
const ShelfModule = (i: ShelfModuleData) => {
  const [isOpenValueConfig, setOpenValueConfig] = useState<any>(false);

  return (
    <div className={styles.shelf} key={`S${i.j}`}>
      <div className="font-bold rounded-md">
        ID&nbsp;
        <input
          className={`${styles.IDInp} ${styles.light}`}
          value={
            i.shelf?.code?.split("-S")[-1] === "0"
              ? ""
              : i.shelf?.code?.split("-S")[-1]
          }
          placeholder="0000"
          onChange={(e) => i.changeShelfId(e.target.value, i.i + 1, i.j + 1)}
          type="text"
        />
      </div>

      {`${i.shelf?.capacity || 0} slots`}

      <div className={`${styles.fnBox} flex gap-2 absolute right-2 top-2`}>
        <img
          className="h-[20px] w-[20px]"
          onClick={() =>
            setOpenValueConfig({
              value: {
                capacity: i.shelf?.capacity || 0,
              },
              state: true,
            })
          }
          src={icon.edit}
          alt=""
        />
        <img
          className="h-[20px] w-[20px]"
          onClick={() => i.deleteShelf(i.frag, i.shelf)}
          src={icon.trash.white}
          alt=""
        />
      </div>
      <div className="w-[50px] h-full"> </div>
      {isOpenValueConfig.state && (
        <ValueConfigModal
          currentValue={{
            key: "Số lượng slot",
            value: i.shelf?.capacity || 0,
          }}
          setValue={(capacity: number) =>
            i.changeShelfCapacity(capacity, i.shelf, i.frag)
          }
          setClose={setOpenValueConfig}
        />
      )}
    </div>
  );
};

export interface ShelfsProps {
  lineState: any;
  changeShelfId: any;
  deleteShelf: any;
  changeShelfCapacity: any;
  addShelf: any;
}

const Shelfs = (props: ShelfsProps) => {
  const {
    lineState,
    changeShelfId,
    deleteShelf,
    changeShelfCapacity,
    addShelf,
  } = props;
  return (
    <div className="shelves mt-4 pr-[35px] py-4 overflow-scroll max-h-[300px] flex gap-4 items-center">
      {lineState.fragments.map((frag: any, i: any) => {
        return (
          frag.using && (
            <div
              key={`Fragment2${i}`}
              className="flex items-center flex-col w-full gap-4"
            >
              {frag.shelves.map((shelf: any, j: any) => {
                return (
                  <ShelfModule
                    i={i}
                    j={j}
                    shelf={shelf}
                    changeShelfId={changeShelfId}
                    deleteShelf={deleteShelf}
                    changeShelfCapacity={changeShelfCapacity}
                    frag={frag}
                  />
                );
              })}
              <img
                className="min-w-[250px] h-[20px] w-[20px]"
                src={icon.add}
                alt=""
                onClick={() => addShelf(frag)}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default Shelfs;
