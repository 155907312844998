import { icon } from "../../../assets/imgGetter";
import styles from "../styles.module.scss";

export interface TitleProps {
  className?: string;
  unCloseable?: boolean;
  ClosePanel: any;
}

const Title = (props: TitleProps) => {
  const { unCloseable, ClosePanel } = props;
  return (
    <div className="flex justify-between items-center mb-10">
      <div className={styles.title}>Tạo mới dãy</div>
      {!unCloseable && (
        <img
          src={icon.close}
          onClick={() => ClosePanel(true)}
          className="hover:opacity-100 opacity-80 cursor-pointer transition-opacity"
          alt=""
        />
      )}
    </div>
  );
};

export default Title;
