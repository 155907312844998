import { createSlice } from "@reduxjs/toolkit";

const selectedItemsSplice = createSlice({
  name: "selectedItems",
  initialState: {
    selectedItems: {
      lineID: null,
      shelfID: null,
    },
  },
  reducers: {
    selectedLine: (state, action) => {
      const idItemPayloadLineID = action.payload;
      const URL = window.location.pathname;
      const lineID = state.selectedItems.shelfID?.split("-")[0];

      if (URL === "/whm") {
        state.selectedItems.lineID = idItemPayloadLineID;
        if (lineID !== idItemPayloadLineID) {
          state.selectedItems.shelfID = null;
        }
      }
    },

    selectedShelf: (state, action) => {
      const idItemPayloadShelfID = action.payload;
      const URL = window.location.pathname;
      const lineID = idItemPayloadShelfID.split("-")[0];
      if (URL === "/whm") {
        state.selectedItems.shelfID = idItemPayloadShelfID;
        state.selectedItems.lineID = lineID;
      }
    },
  },
});

// Reducer
const selectedItemsReducer = selectedItemsSplice.reducer;

// Selector
export const selectedItemsSelectorLineID = (state) =>
  state.selectedItemsReducer.selectedItems.lineID;
export const selectedItemsSelectorShelfID = (state) =>
  state.selectedItemsReducer.selectedItems.shelfID;

// Action export
export const { selectedLine, selectedShelf } = selectedItemsSplice.actions;

export default selectedItemsReducer;
