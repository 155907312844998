import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Fragment, useEffect, useState } from "react";
import { icon } from "../../../../assets/imgGetter";
import Option from "../../../../components/Option";
import PageLoading from "../../../../components/PageLoading";
import PkgComponent from "../../../../components/PkgComp";
import LineName from "../LineName/LineName";
import ModalCheckPickup from "../ModalCheckPickup/ModalCheckPickup";
import SlotMapPickup from "../SlotMapPickup/SlotMapPickup";
import styles from "./styles.module.scss";

export interface ModalProps {
  handleClose: () => void;
  open: boolean;
  index?: number;
  className?: String;
  deliveryDate: any;
  status: String;
  ID: string;
  orderDataState: any;
  orderSelectedData: any;
  setOrderIDPickState: any;
  setOrderIDPackState: any;
  setLines: any;
  loading: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80%",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 4,
};

const ModalOrder = (props: ModalProps) => {
  const {
    handleClose,
    open,
    index,
    orderDataState,
    orderSelectedData,
    ID,
    setOrderIDPickState,
    setOrderIDPackState,
    status,
    setLines,
    loading,
  } = props;

  const [selectedLine, setSelectedLine] = useState<any>(0);
  const [currentFragmentId, setCurrentFragmentId] = useState<any>([]);
  const [optionValue, setOptionValue] = useState([]);

  const [fragmentID, setFragmentID] = useState<any>([]);
  const [fragmentData, setFragmentData] = useState<any>([]);
  const [shelfNameState, setShelfNameState] = useState<any>([]);

  const [openChild, setOpenChild] = useState(false);
  const handleOpen = () => {
    setOpenChild(true);
  };

  let lineSelected = orderDataState?.[selectedLine];

  const allFragmentCode = lineSelected?.partner_warehouse_groups?.map(
    (item: any) => item.code
  );

  const fragmentBox = {
    value: allFragmentCode,
    content: allFragmentCode,
  };

  const handleSelectedLine = (key: any) => {
    setSelectedLine(key);
  };

  const dataFragFormId = (id: any) => {
    const fragments = lineSelected?.partner_warehouse_groups?.filter(
      (item: any) => item.code === id
    );
    return fragments;
  };

  useEffect(() => {
    const fragments = dataFragFormId(currentFragmentId);

    const allIdShelf = fragments?.[0]?.partner_warehouse_groups?.map(
      (item: any) => item.id
    );

    const allCodeShelf = fragments?.[0]?.partner_warehouse_groups?.map(
      (item: any) => item.code
    );

    setShelfNameState(allCodeShelf);
    setFragmentData(fragments);
    setFragmentID(allIdShelf);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFragmentId]);

  const slotInLine = orderDataState
    ?.map((item: any) => {
      return item.partner_warehouse_groups?.map((item: any) => {
        return item.partner_warehouse_groups?.map((item: any) => {
          return item.partner_warehouse_slots.map((item: any) => {
            return item;
          });
        });
      });
    })
    ?.flat(Infinity);

  const handleSendOptionValue = () => {
    setOpenChild(true);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setOptionValue([]);
        setSelectedLine(0);
      }}
    >
      <PageLoading isLoading={loading}>
        <Box sx={style}>
          <Fragment>
            <div
              className={
                styles.modalCoverStyles +
                " header mb-4 flex items-center w-[100%] justify-between max-h-[5%]"
              }
            >
              <div className="header mb-4 flex items-center w-[50%] justify-between max-h-[5%]">
                <div className="flex">
                  <img
                    src={icon.caret.left.default}
                    className={styles.backBtn}
                    alt=""
                    onClick={() => {
                      return (
                        handleClose(), setOptionValue([]), setSelectedLine(0)
                      );
                    }}
                  />
                  <div className="ml-[3rem] font-bold text-[1.8rem]">
                    {currentFragmentId?.length &&
                      currentFragmentId?.split("-")?.[0]}
                  </div>
                </div>
                <Option
                  selected={index}
                  option={fragmentBox}
                  setSelected={setCurrentFragmentId}
                  valueCheckReset={selectedLine}
                />
              </div>
              <div className={styles.orderInfoWrap}>
                <PkgComponent
                  className="grow"
                  status={status}
                  ID={orderSelectedData.order_id}
                />
              </div>
            </div>

            <div className="content flex md:flex-row flex-col h-[80%] ">
              <div className={"grow order-last md:-order-last"}>
                <div className="title px-8 py-8 grid grid-cols-2 gap-8 mb-4 max-h-[100%] h-[100%] overflow-hidden	">
                  {fragmentID?.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <SlotMapPickup
                          orderDataState={orderDataState}
                          shelfID={item}
                          shelfNameState={shelfNameState[index]}
                          icon={icon}
                          setOptionValue={setOptionValue}
                          selectedLine={selectedLine}
                          dataCheckedInput={optionValue}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </div>
              <div className={styles.function + " py-8"}>
                <div className={styles.shelfWrap}>
                  <div className={styles.shelfTitle}>Các dãy liên quan</div>
                  <div className={`${styles.lineList}`}>
                    {orderDataState?.map((line: any, index: number) => {
                      return (
                        <LineName
                          handleSelectedLine={handleSelectedLine}
                          line={line}
                          index={index}
                          icon={icon}
                          fragmentData={fragmentData}
                          optionValue={optionValue}
                          selectedLine={selectedLine}
                        />
                      );
                    })}
                  </div>
                </div>

                <button
                  className={styles.button + " text-2xl"}
                  onClick={() => {
                    handleSendOptionValue();
                  }}
                  disabled={loading}
                >
                  Pickup hàng
                </button>
              </div>
            </div>

            <ModalCheckPickup
              openChild={openChild}
              setOpenChild={() => setOpenChild(false)}
              handleOpen={() => handleOpen()}
              optionValue={optionValue}
              slotInLine={slotInLine}
              orderId={orderSelectedData.order_id}
              setOrderIDPickState={setOrderIDPickState}
              setOrderIDPackState={setOrderIDPackState}
              handleClose={handleClose}
              status={status}
              setLines={setLines}
            />
            <div className="note mt-16 max-h-[15%]">
              <ul className="flex gap-8">
                <li className="flex">
                  <img src={icon.slotStatus.needPickup} alt="" />
                  <p className="ml-4 text-2xl">Sào cần pickup</p>
                </li>
              </ul>
            </div>
          </Fragment>
        </Box>
      </PageLoading>
    </Modal>
  );
};

export default ModalOrder;
