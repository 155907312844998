import { Fragment, useRef, useState } from "react";
import { icon } from "../../assets/imgGetter";
import { ILine } from "../../interface/data";
import Option from "../Option";
import SlotsMap from "../SlotsMap/SlotsMap";
import styles from "./styles.module.scss";
type propsType = {
  line: ILine;
  closeHandler: any;
  index: number;
};

function SideBoxModal(props: propsType) {
  const { index } = props;
  const [openState, changeOpenState] = useState(true);
  const [currentFragmentId, setCurrentFragmentId] = useState("");
  const [valueFilter, setValueFilter] = useState("");

  const [optionValue, setOptionValue] = useState([]);

  const currentFragment = props.line.fragments.find(
    (frag) => frag.code === currentFragmentId
  );
  const optionsBox = {
    value: props.line.fragments.map((f) => f.code.toString()),
    content: props.line.fragments.map((f) => f.name.toString()),
  };
  const optionsBoxFilterStatus = {
    value: ["", "1", "2", "3", "4"],
    content: ["Tất cả", "Móc trống", "Sẵn có", "Đã đặt hàng", "Đang đóng gói"],
  };

  const shelves = currentFragment?.shelves;

  let arrOptionValueRef = useRef<any>();

  const valueCheckbox = (value: any) => {
    if (arrOptionValueRef.current === undefined) {
      arrOptionValueRef.current = [];
    }
    if (arrOptionValueRef.current.includes(value)) {
      arrOptionValueRef.current = arrOptionValueRef.current.filter(
        (item: any) => item !== value
      );
    } else {
      arrOptionValueRef.current.push(value);
    }
    setOptionValue(arrOptionValueRef.current);
    console.log("arrOptionValueRef.current", arrOptionValueRef.current);
  };

  return (
    <div className="Modal">
      <div
        className={
          styles.modalCover + " " + (!openState && styles.modalCoverClose)
        }
        onClick={() => {
          return changeOpenState(false);
        }}
      ></div>
      <div
        className={styles.sideBox + " " + (!openState && styles.modalClose)}
        onAnimationEnd={() => !openState && props.closeHandler()}
      >
        <div className={styles.mainContainer}>
          <div className="header mb-4 flex items-center w-full justify-between max-h-[5%]">
            <div className="flex">
              <img
                src={icon.caret.left.default}
                className={styles.backBtn}
                alt=""
                onClick={() => {
                  return changeOpenState(false);
                }}
              />
              <div className="ml-[3rem] font-bold text-[1.8rem]"></div>
              {/* <SearchBox searchValue={searchValue} /> */}
              <Option
                option={optionsBoxFilterStatus}
                setSelected={setValueFilter}
              />
            </div>

            <Option
              selected={index}
              option={optionsBox}
              setSelected={setCurrentFragmentId}
            />
          </div>
          <div className="content flex md:flex-row flex-col h-[95%]">
            <div className={"grow order-last md:-order-last"}>
              <div className="title px-8 grid grid-cols-2 gap-8 mb-4 max-h-[90%] h-[100%]">
                {shelves?.length ? (
                  shelves?.map((shelf, index) => {
                    return (
                      <Fragment key={index}>
                        <SlotsMap
                          icon={icon}
                          shelf={shelf}
                          valueFilter={valueFilter}
                          setOptionValue={setOptionValue}
                          valueCheckbox={valueCheckbox}
                        />
                      </Fragment>
                    );
                  })
                ) : (
                  <p>Hiện tại không có slot nào trong phân kệ</p>
                )}
              </div>

              <div className="note mt-16 max-h-[5%]">
                <ul className="flex gap-8">
                  <li className="flex">
                    <img src={icon.slotStatus.pack} alt="" />
                    <p className="ml-4">Đang đóng gói</p>
                  </li>

                  <li className="flex">
                    <img src={icon.slotStatus.allocated} alt="" />
                    <p className="ml-4">Đặt hàng</p>
                  </li>
                  <li className="flex">
                    <img src={icon.slotStatus.available} alt="" />
                    <p className="ml-4">Đã treo</p>
                  </li>
                  <li className="flex">
                    <img src={icon.slotStatus.empty} alt="" />
                    <p className="ml-4">Trống</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBoxModal;
