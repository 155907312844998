import { createSlice } from "@reduxjs/toolkit";

const warehouseSplice = createSlice({
  name: "warehouse",
  initialState: {
    warehouse: {
      info: {},
      data: {
        lines: [],
      },
    },
    refesh: false,
    detailShelfClick: null,
  },
  reducers: {
    setWarehouseInfo: (state, action) => {
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          info: action.payload,
        },
      };
    },

    setData: (state, action) => {
      const addUsingTrueArray = action.payload?.line?.map((line) => {
        return {
          ...line,
          using: true,
          fragments: line?.fragments?.map((fragment) => {
            return {
              ...fragment,
              using: true,
              shelves: fragment?.shelves?.map((shelf) => {
                return {
                  ...shelf,
                  using: true,
                };
              }),
            };
          }),
        };
      });

      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          data: { lines: addUsingTrueArray },
        },
      };
    },
    setInit: (state, action) => {
      return { ...state, warehouse: action.payload };
    },
    addLine: (state, action) => {
      state.warehouse.data.lines.push(action.payload);
    },
    updateLine: (state, action) => {
      const tempLine = [...state.warehouse.data.lines];
      const i = tempLine.indexOf((l) => l.id === action.payload.lineId);
      tempLine.splice(i, 1, action.payload.line);
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          data: { ...state.warehouse.data, lines: tempLine },
        },
      };
    },
    deleteLine: (state, action) => {
      const id = state.warehouse.data.lines.indexOf(
        (r) => r.code === action.payload
      );
      state.warehouse.data.lines.splice(id, 1);
    },

    showInfoShelf: (state, action) => {
      state.detailShelfClick = action.payload;
    },

    refeshData: (state, action) => {
      return { ...state, refesh: !state.refesh };
    },
  },
});

// Reducer
const warehouseReducer = warehouseSplice.reducer;

// Selector
export const warehouseSelector = (state) => state.warehouseReducer.warehouse;
export const detailShelfClickSelector = (state) =>
  state.warehouseReducer.detailShelfClick;

// Action export
export const {
  setInit,
  setWarehouseInfo,
  setData,
  addLine,
  updateLine,
  deleteLine,
  showInfoShelf,
  refeshData,
} = warehouseSplice.actions;

export default warehouseReducer;
