import { icon } from "../../../assets/imgGetter";
import styles from "../styles.module.scss";

export interface FragmentsProps {
  className?: string;
  lineState: any;
  changeFragmentId: any;
  toggleFragment: any;
  deleteFragment: any;
  addFragment: any;
}

const Fragments = (props: FragmentsProps) => {
  const {
    lineState,
    changeFragmentId,
    toggleFragment,
    deleteFragment,
    addFragment,
  } = props;
  return (
    <div className="line items-center mb-6 w-full flex gap-4">
      <div className="fragment overflow-y-scroll py-4 items-center flex gap-4 w-full">
        {lineState.fragments.map((frag: any, j: any) => {
          const display = frag.using ? (
            <div className="w-full" key={`FragmentContainer${j}`}>
              <div className={styles.fragment}>
                <div className="font-bold rounded-md">
                  ID&nbsp;
                  <input
                    className={`${styles.IDInp} ${styles.dark}`}
                    value={
                      frag.code.split("-F")[1] === "0"
                        ? ""
                        : frag.code.split("-F")[1]
                    }
                    placeholder="0000"
                    onChange={(e) => changeFragmentId(e.target.value, j + 1)}
                    type="text"
                  />
                </div>
                {`# ${frag.code}`}
                <div
                  className={`${styles.fnBox} flex gap-2 absolute right-2 top-5`}
                >
                  <img
                    className="h-[20px] w-[20px]"
                    onClick={() => toggleFragment(frag)}
                    src={icon.trash.white}
                    alt=""
                  />
                </div>
                <div className="w-[50px]"> </div>
              </div>
            </div>
          ) : (
            <div
              className="min-w-[150px] min-h-[50px] w-full flex justify-center"
              key={`ToggleF${j}`}
            >
              <img
                className="min-h-[20px] min-w-[20px]"
                onClick={() => toggleFragment(frag)}
                src={icon.add}
                alt=""
              />
            </div>
          );
          return (
            <div className=" w-full flex flex-col gap-4">
              <div
                className="min-w-[150px] w-full grow flex justify-center"
                key={`DeleteF${j}`}
              >
                <img
                  className="min-h-[20px] min-w-[20px]"
                  onClick={() => deleteFragment(frag)}
                  src={icon.trash.default}
                  alt=""
                />
              </div>
              {display}
            </div>
          );
        })}
      </div>
      {/* Button line function */}
      <img
        className="h-[20px] w-[20px]"
        onClick={() => addFragment()}
        src={icon.add}
        alt=""
      />
    </div>
  );
};

export default Fragments;
