import { usePreviousProps } from "@mui/utils";
import { useEffect, useState } from "react";
import imgGetter from "../../assets/imgGetter";
import ModalOut from "../ModalOut/modalOut";
import defaultStyles from "./option.module.scss";

type optionProps = {
  option: {
    value: Array<string>;
    content: Array<string>;
  };
  setSelected?: any;
  styles?: {
    readonly [key: string]: string;
  };
  caret?: string;
  className?: string;
  selected?: number;
  valueCheckReset?: any;
};

function Option(props: optionProps) {
  const { selected, setSelected, valueCheckReset } = props;
  const prevValueCheckReset = usePreviousProps(valueCheckReset);

  const styles = { ...defaultStyles, ...props.styles };
  const caret = props.caret || imgGetter.icon.caret.down.white;
  const [resetSelected, setResetSelected] = useState(false);

  const [collapse, setCollapse] = useState(true);
  const [selectedId, setSelectedId] = useState(selected || 0);
  function handleSelectOpt(val: number) {
    setSelectedId(val);
  }

  useEffect(() => {
    setSelected && setSelected(props.option?.value?.[selectedId]);

    if (prevValueCheckReset !== valueCheckReset) {
      setResetSelected(true);
    }

    if (resetSelected) {
      setSelectedId(0);
      setResetSelected(false);
    }

    setCollapse(true);
  }, [
    prevValueCheckReset,
    props,
    resetSelected,
    selectedId,
    valueCheckReset,
    setSelected,
  ]);

  return (
    <div className={"Option relative w-fit " + props.className}>
      {!collapse && <ModalOut />}
      <div
        className={styles.container + " flex gap-4 justify-between"}
        onClick={() => setCollapse((prev) => !prev)}
      >
        <p className={styles.text}>{props.option?.content?.[selectedId]}</p>
        <img style={{ maxWidth: "none" }} src={caret} alt="" />
      </div>
      {!collapse && (
        <div className={styles.options}>
          {props.option?.value.map((val, i) => {
            return (
              <div
                className={`${selectedId === i ? styles.selectedOpt : " "}
                        ${styles.option}`}
                key={i}
                {...{ value: val }}
                onClick={() => handleSelectOpt(i)}
              >
                {props.option?.content[i]}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Option;
