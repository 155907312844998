import { gql, useQuery } from "@apollo/client";

export interface getCurrentProfileProps {
  dataLogin: any;
}

export default function GetCurrentProfile(props: any) {
  const { dataLogin } = props;

  let login = gql`
    query MyQuery {
      get_current_profile {
        id
        avatar
        email
        name
        phoneNumber
      }
    }
  `;
  const { data, loading, error } = useQuery(login);

  if (data || dataLogin) {
    localStorage.setItem("currentProfile", JSON.stringify(data));
  }

  if (loading) return <></>;
  if (error) {
    localStorage.removeItem("currentProfile");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
  }

  return <>{props.children}</>;
}
