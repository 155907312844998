import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { icon } from "../../assets/imgGetter";
import {
  addGroupQuery,
  createPartnerWarehouseSlotsQuery,
  deleteGroupQuery,
  editGroupQuery,
} from "../../gql/gql";
import { IFragment, ILine, IShelf } from "../../interface/data";

import ModalOut from "../ModalOut/modalOut";
import PageLoading from "../PageLoading";
import ValueConfigModal from "../ValueConfigModal";
import styles from "./styles.module.scss";

type propsType = {
  line: ILine;
  setClose?: any;
  deleteLineHandler?: any;
  unCloseable?: any;
  setIsLoading?: any;
  isLoading?: any;
  setLines: any;
};

interface TestModalData {
  i: any;
  j: any;
  shelf: IShelf;
  changeShelfId: any;
  deleteShelf: any;
  changeShelfCapacity: any;
  frag: IFragment;
}

const TestModal = ({
  i,
  j,
  shelf,
  changeShelfId,
  deleteShelf,
  changeShelfCapacity,
  frag,
}: TestModalData) => {
  const [isOpenValueConfig, setOpenValueConfig] = useState<any>(false);
  return (
    <div className={styles.shelf} key={`S${j}`}>
      <div className="font-bold rounded-md">
        ID&nbsp;
        <input
          className={`${styles.IDInp} ${styles.light}`}
          value={
            shelf?.code?.split("-S")[1] === "0"
              ? 0
              : shelf?.code?.split("-S")[1]
          }
          placeholder="0000"
          onChange={(e) => changeShelfId(e.target.value, i + 1, j + 1)}
          type="text"
        />
      </div>

      {`${shelf.capacity} slots`}

      <div className={`${styles.fnBox} flex gap-2 absolute right-2 top-2`}>
        {!shelf.id ? (
          <img
            className="h-[20px] w-[20px]"
            onClick={() =>
              setOpenValueConfig({
                value: {
                  capacity: shelf.capacity || 0,
                },
                state: true,
              })
            }
            src={icon.edit}
            alt=""
          />
        ) : null}

        <img
          className="h-[20px] w-[20px]"
          onClick={() => deleteShelf(frag, shelf)}
          src={icon.trash.white}
          alt=""
        />
      </div>
      <div className="w-[50px] h-full"> </div>
      {isOpenValueConfig.state && (
        <ValueConfigModal
          currentValue={{
            key: "Số lượng slot",
            value: shelf.capacity || 0,
          }}
          setValue={(capacity: number) =>
            changeShelfCapacity(capacity, shelf, frag)
          }
          setClose={setOpenValueConfig}
        />
      )}
    </div>
  );
};

function LinePanel(props: propsType) {
  const { setIsLoading, isLoading, line, setLines } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [lineState, updateLine] = useState(line);
  console.log(lineState, "lineState");

  const [editPartnerGroupLine] = useMutation(editGroupQuery);

  const [editPartnerGroupFragment] = useMutation(editGroupQuery);

  const [editPartnerGroupShelf] = useMutation(editGroupQuery);

  const [createPartnerGroupFragment] = useMutation(addGroupQuery);

  const [createPartnerGroupShelf] = useMutation(addGroupQuery);

  const [deletePartnerWarehouseGroup] = useMutation(deleteGroupQuery);

  const [createPartnerWarehouseSlots] = useMutation(
    createPartnerWarehouseSlotsQuery
  );

  const currentProfile = useSelector(
    (state: any) =>
      state.warehouseReducer.warehouse.info.partner_warehouses[0].id
  );
  const parseToArr = (idShelf: string, capacity: number) => {
    const arr = [];
    for (let i = 0; i < capacity; i++) {
      arr.push(
        `${idShelf.split("-")[2].replace("S", "")}${i < 10 ? "0" : ""}${i}`
      );
    }
    return arr;
  };

  function validate() {
    if (lineState.id === "")
      setWarning({ for: "LineID", content: "Dãy chưa có ID", status: true });
    if (warning.status) return;
    setWarning({ for: "", content: "", status: false });

    const editLine = () => {
      setIsLoading(true);
      return editPartnerGroupLine({
        variables: {
          code: lineState?.code,
          id: lineState?.id,
          name: lineState?.name,
          order_number: lineState?.orderNumber,
          parent_partner_warehouse_group_id: "",
          partner_warehouse_id: currentProfile,
          slot: lineState.capacity,
          status: lineState.using ? "active" : "inactive",
          warehouse_group_type_id: "line",
        },
      });
    };

    const editFragmentFromIDResponse = async () => {
      try {
        const response = await editLine();
        if (response) {
          const arrEditPartnerGroupFragmentPromise: Promise<void>[] = [];
          const idLine = response.data.editPartnerWarehouseGroup.id;
          if (idLine) {
            lineState?.fragments.forEach(async (frag: any, index) => {
              const editFragment = async () => {
                if (frag?.id) {
                  const response = await editPartnerGroupFragment({
                    variables: {
                      code: frag?.code,
                      name: frag?.name,
                      id: frag?.id,
                      order_number: frag.orderNumber,
                      parent_partner_warehouse_group_id: lineState.id,
                      partner_warehouse_id: currentProfile,
                      slot: frag.capacity,
                      status: frag.using ? "active" : "inactive",
                      warehouse_group_type_id: "fragment",
                    },
                  });
                  const arrEditPartnerGroupShelfPromise: Promise<any>[] = [];
                  const arrCreatePartnerGroupShelfPromise: Promise<any>[] = [];

                  if (response) {
                    const idFragment =
                      response?.data?.editPartnerWarehouseGroup?.id ||
                      response?.data?.createPartnerWarehouseGroup?.id;

                    frag?.shelves.forEach((shelf: any, key: any) => {
                      if (shelf.id) {
                        arrEditPartnerGroupShelfPromise.push(
                          editPartnerGroupShelf({
                            variables: {
                              code: shelf?.code,
                              name: shelf?.name,
                              id: shelf?.id,
                              order_number: shelf.orderNumber,
                              parent_partner_warehouse_group_id: frag.id,
                              partner_warehouse_id: currentProfile,
                              slot: shelf.capacity,
                              status: shelf.using ? "active" : "inactive",
                              warehouse_group_type_id: "shelf",
                            },
                          })
                        );
                      } else {
                        const a = async () => {
                          const response = await createPartnerGroupShelf({
                            variables: {
                              code: shelf?.code,
                              name: shelf?.name,
                              order_number: key + 1,
                              parent_partner_warehouse_group_id: idFragment,
                              partner_warehouse_id: currentProfile,
                              slot: shelf.capacity,
                              status: "active",
                              warehouse_group_type_id: "shelf",
                            },
                          });
                          if (response) {
                            const idShelf =
                              response.data.createPartnerWarehouseGroup.id;
                            if (idShelf) {
                              await createPartnerWarehouseSlots({
                                variables: {
                                  codes: parseToArr(
                                    shelf?.code,
                                    shelf.capacity
                                  ),
                                  partner_warehouse_group_id: idShelf,
                                  partner_warehouse_id: currentProfile,
                                },
                              });
                            }
                          }
                        };
                        arrCreatePartnerGroupShelfPromise.push(a());
                      }
                    });
                  }
                  await Promise.all(arrEditPartnerGroupShelfPromise);
                  await Promise.all(arrCreatePartnerGroupShelfPromise);
                } else {
                  const response = await createPartnerGroupFragment({
                    variables: {
                      code: frag?.code,
                      name: frag?.name,
                      order_number: frag.orderNumber,
                      parent_partner_warehouse_group_id: lineState.id,
                      partner_warehouse_id: currentProfile,
                      slot: frag.capacity,
                      status: "active",
                      warehouse_group_type_id: "fragment",
                    },
                  });
                  const arrCreatePartnerGroupShelfPromise: Promise<any>[] = [];
                  if (response) {
                    const idFragment =
                      response?.data?.createPartnerWarehouseGroup?.id;
                    frag?.shelves.forEach((shelf: any, key: any) => {
                      const a = async () => {
                        const response = await createPartnerGroupShelf({
                          variables: {
                            code: shelf?.code,
                            name: shelf?.name,
                            order_number: key + 1,
                            parent_partner_warehouse_group_id: idFragment,
                            partner_warehouse_id: currentProfile,
                            slot: shelf.capacity,
                            status: "active",
                            warehouse_group_type_id: "shelf",
                          },
                        });
                        if (response) {
                          const idShelf =
                            response.data.createPartnerWarehouseGroup.id;
                          if (idShelf) {
                            await createPartnerWarehouseSlots({
                              variables: {
                                codes: parseToArr(shelf?.code, shelf.capacity),
                                partner_warehouse_group_id: idShelf,
                                partner_warehouse_id: currentProfile,
                              },
                            });
                          }
                        }
                      };
                      arrCreatePartnerGroupShelfPromise.push(a());
                    });
                  }
                  await Promise.all(arrCreatePartnerGroupShelfPromise);
                }
              };
              arrEditPartnerGroupFragmentPromise.push(editFragment());
            });
          }
          await Promise.all(arrEditPartnerGroupFragmentPromise);
        }
        setLines();
        ClosePanel(true);
        setIsLoading(false);
        enqueueSnackbar("Thêm dãy thành công", { variant: "success" });
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Có lỗi xảy ra", { variant: "error" });
      }
    };

    editFragmentFromIDResponse();
  }

  const [warning, setWarning] = useState({
    for: "LineID",
    status: false,
    content: "",
  });
  const [isClosing, ClosePanel] = useState(false);

  useEffect(() => {
    updateLine((prev) => {
      return { ...updateLineForm(prev) };
    });
  }, []);

  // Change the layout data
  const updateLineForm = (line: ILine) => {
    line.totalFragment = line.fragments.length;
    line.fragments.forEach((fragment: IFragment) => {
      fragment.totalShelf = fragment.shelves.length;
    });
    line.totalShelf = line.fragments.reduce(
      (totalShelf: number, fragment: IFragment) =>
        totalShelf + fragment.totalShelf,
      0
    );
    return line;
  };

  const updateLineCode = (line: ILine) => {
    line.code = `L${line.code.split("L").pop()}`;
    line.name = `Line ${line.code.split("L").pop()}`;
    line.fragments.forEach((fragment: IFragment) => {
      fragment.code = `${line.code}-F${fragment.code.split("-F").pop()}`;
      fragment.name = `Phân kệ ${fragment.code}`;
      fragment.shelves.forEach((shelf: IShelf) => {
        shelf.code = `${fragment.code}-S${shelf.code.split("-S").pop()}`;
        shelf.name = `Kệ ${shelf.code}`;
      });
    });

    return line;
  };

  function changeLineId(lineId: String) {
    updateLine((prev) => {
      let temp = prev;
      temp.code = lineId || "0";
      updateLineCode(temp);
      return { ...temp };
    });
  }

  function changeFragmentId(fragmentId: String, orderNumber: number) {
    updateLine((prev) => {
      let temp = prev;
      temp.fragments[orderNumber - 1].code = fragmentId || "0";
      updateLineCode(temp);
      return { ...temp };
    });
  }

  function changeShelfId(
    shelfId: String,
    fragmentON: number,
    orderNumber: number
  ) {
    updateLine((prev) => {
      let temp = prev;
      temp.fragments[fragmentON - 1].shelves[orderNumber - 1].code =
        shelfId || "0";
      updateLineCode(temp);
      return { ...temp };
    });
  }

  // Shelves feature

  function addShelf(parentFragment: IFragment) {
    let newShelf: IShelf = {
      id: "",
      orderNumber: parentFragment.totalShelf
        ? parentFragment.totalShelf + 1
        : 1,
      code: `${parentFragment.code}-S0`,
      name: `Kệ ${parentFragment.code}-S0`,
      priority: 3,
      using: true,
      capacity: 0,
    };

    updateLine((prev) => {
      let temp = prev;
      temp.fragments[parentFragment.orderNumber - 1].shelves.push(newShelf);
      temp = updateLineForm(temp);
      return { ...temp };
    });
  }

  async function deleteShelf(fragment: IFragment, shelf: IShelf) {
    updateLine((prev) => {
      let temp = prev;
      temp.fragments[fragment.orderNumber - 1].shelves.splice(
        shelf.orderNumber - 1,
        1
      );

      updateLineForm(temp);
      return { ...temp };
    });

    const response = await deletePartnerWarehouseGroup({
      variables: {
        id: shelf.id,
      },
    });

    if (response) {
      window.location.reload();
      enqueueSnackbar("Xóa thành công", { variant: "success" });
    } else {
      enqueueSnackbar("Xóa thất bại", { variant: "error" });
    }
  }

  function changeShelfCapacity(
    capacity: number,
    shelf: IShelf,
    fragment: IFragment
  ) {
    updateLine((prev) => {
      let temp = prev;
      fragment.shelves[shelf.orderNumber - 1].capacity = parseInt(
        capacity.toString()
      );
      fragment.capacity = fragment.shelves.reduce(
        (c: number, sh: IShelf) => sh.capacity + c,
        0
      );

      temp.capacity = temp.fragments.reduce(
        (c: number, f: IFragment) => f.capacity + c,
        0
      );
      return { ...temp };
    });
  }
  // Fragment feature
  function addFragment() {
    let newFragment: IFragment = {
      id: "",
      code: `L${lineState.code}-F0`,
      orderNumber: lineState.totalFragment ? lineState.totalFragment + 1 : 1,
      priority: 2,
      capacity: 0,
      using: true,
      name: `Phân kệ L${lineState.code}-F0`,
      totalShelf: 1,
      shelves: [
        {
          id: "",
          code: "0",
          orderNumber: 1,
          capacity: 0,
          priority: 3,
          using: true,
          name: "Kệ 1",
        },
      ],
    };

    updateLine((prev) => {
      let temp = prev;
      temp.fragments.push(newFragment);
      temp = updateLineForm(temp);
      temp = updateLineCode(temp);
      return { ...temp };
    });
  }

  async function deleteFragment(fragment: IFragment) {
    updateLine((prev) => {
      if (prev.totalFragment === 1) return { ...prev };

      let temp = prev;
      let itemsBehide = prev.fragments.slice(fragment.orderNumber);

      itemsBehide.forEach((item: IFragment) => {
        item.orderNumber--;
      });
      temp.fragments.splice(fragment.orderNumber - 1, 1);

      updateLineForm(temp);

      return { ...temp };
    });
    const response = await deletePartnerWarehouseGroup({
      variables: {
        id: fragment.id,
      },
    });

    if (response) {
      window.location.reload();
      enqueueSnackbar("Xóa thành công", { variant: "success" });
    } else {
      enqueueSnackbar("Xóa thất bại", { variant: "error" });
    }
  }

  function toggleFragment(fragment: IFragment) {
    updateLine((prev) => {
      if (prev.totalFragment === 1 && fragment.using) return { ...prev };
      fragment.using = !fragment.using;

      return { ...prev };
    });
  }

  return (
    <div
      className={`${styles.rackPanel} ${isClosing ? styles.modalClose : " "}`}
      onAnimationEnd={() => (isClosing ? props.setClose(false) : () => {})}
    >
      {!props?.unCloseable && <ModalOut onClick={() => ClosePanel(true)} />}
      <PageLoading isLoading={isLoading}>
        <div className={styles.container}>
          <div className="mb-10">
            <div className="flex justify-between items-center mb-10">
              <div className={styles.title}>Cài đặt dãy {lineState.code}</div>
              {!props?.unCloseable && (
                <img
                  src={icon.close}
                  onClick={() => ClosePanel(true)}
                  className="hover:opacity-100 opacity-80 cursor-pointer transition-opacity"
                  alt=""
                />
              )}
              {props?.unCloseable && <div></div>}
            </div>
            {/* LineID */}
            <h3 className="uppercase font-semibold">
              ID dãy :
              <input
                className={styles.IDInp}
                defaultValue={lineState.code.toString()}
                placeholder="0000"
                onChange={(e) => {
                  setWarning((prev) =>
                    e.target.value !== ""
                      ? { for: "", status: false, content: "" }
                      : prev
                  );
                  changeLineId(e.target.value);
                }}
                type="text"
              />
            </h3>
            {warning.for === "LineID" && (
              <p className="warning text-orange-600">{warning.content}</p>
            )}
            {/* Line demonstration model */}
            <h3 className="uppercase mt-4 font-semibold">Mô hình dãy</h3>

            {/* Upper view -> Line: Fragments*/}
            <p>Từ trên xuống (Các phân kệ)</p>
            <div className="line items-center mb-6 w-full flex gap-4">
              <div className="fragment overflow-y-scroll py-4 items-center flex gap-4 w-full">
                {lineState.fragments.map((frag: any, j: any) => {
                  const display = (
                    <div className="w-full" key={`FragmentContainer${j}`}>
                      <div className={styles.fragment}>
                        <div className="font-bold rounded-md">
                          ID&nbsp;
                          <input
                            className={`${styles.IDInp} ${styles.dark}`}
                            value={
                              frag.code.split("-F")[1] === "0"
                                ? ""
                                : frag.code.split("-F")[1]
                            }
                            placeholder="0000"
                            onChange={(e) =>
                              changeFragmentId(e.target.value, j + 1)
                            }
                            type="text"
                          />
                        </div>
                        {`# ${frag.code}`}
                        <div
                          className={`${styles.fnBox} flex gap-2 absolute right-2 top-5`}
                        ></div>
                        <div className="w-[50px]"></div>
                      </div>
                    </div>
                  );
                  return (
                    <div className=" w-full flex flex-col gap-4">
                      <div
                        className="min-w-[150px] w-full grow flex justify-center"
                        key={`DeleteF${j}`}
                      >
                        <img
                          className="min-h-[20px] min-w-[20px]"
                          onClick={() => deleteFragment(frag)}
                          src={icon.trash.default}
                          alt=""
                        />
                      </div>
                      {display}
                    </div>
                  );
                })}
              </div>
              {/* Button line function */}
              <img
                className="h-[20px] w-[20px]"
                onClick={() => addFragment()}
                src={icon.add}
                alt=""
              />
            </div>

            {/* Direct view ->  Line : [Shelves x Fragments] */}
            <p>Góc trực tiếp (Các kệ)</p>
            {/* Fragments shelves */}
            <div className="ádasdshelves mt-4 pr-[35px] py-4 overflow-scroll max-h-[300px] flex gap-4 items-center">
              {lineState.fragments.map((frag: any, i: any) => {
                return (
                  <div
                    key={`Fragment2${i}`}
                    className="flex items-center flex-col w-full gap-4"
                  >
                    {frag.shelves.map((shelf: any, j: any) => {
                      return (
                        <Fragment key={j}>
                          <TestModal
                            i={i}
                            j={j}
                            shelf={shelf}
                            changeShelfId={changeShelfId}
                            deleteShelf={deleteShelf}
                            changeShelfCapacity={changeShelfCapacity}
                            frag={frag}
                          />
                        </Fragment>
                      );
                    })}
                    {/* Thêm shelf mới (edit) */}
                    <img
                      className="min-w-[250px] h-[20px] w-[20px]"
                      src={icon.add}
                      alt=""
                      onClick={() => addShelf(frag)}
                    />
                    {/* {console.log("Dữ liệu truyền để thêm shelf", frag)} */}
                  </div>
                );
              })}
            </div>

            {/* Summary */}
            <h3 className="uppercase mt-10 font-semibold">
              Tổng kết thông tin
            </h3>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>ID sào</p>#{lineState.id}
            </div>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>Số lượng phân kệ</p>
              {lineState.totalFragment?.toString()}
            </div>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>Tổng số kệ con</p>
              {lineState.totalShelf?.toString()}
            </div>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>Tổng số lượng slot</p>
              {lineState.capacity?.toString()}
            </div>
          </div>

          <div className="modalBtn flex justify-between">
            <div>
              {!!props.deleteLineHandler && (
                <button
                  className={`${styles.button} ${styles.red}`}
                  onClick={async () => {
                    // ClosePanel(true);
                    props.deleteLineHandler(lineState.code);
                    const res = await deletePartnerWarehouseGroup({
                      variables: {
                        id: lineState.id,
                      },
                    });
                    if (res) {
                      window.location.reload();
                      enqueueSnackbar("Xóa thành công", { variant: "success" });
                    } else {
                      enqueueSnackbar("Xóa thất bại", { variant: "error" });
                    }
                  }}
                >
                  Xóa kệ
                </button>
              )}
            </div>
            <div className="flex gap-4">
              <button onClick={() => validate()} className={`${styles.button}`}>
                Đồng ý
              </button>
              {!props?.unCloseable && (
                <button
                  onClick={() => ClosePanel(true)}
                  className={`${styles.button} ${styles.border}`}
                >
                  Hủy
                </button>
              )}
            </div>
          </div>
        </div>
      </PageLoading>
    </div>
  );
}

export default LinePanel;
