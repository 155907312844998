import { useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import PkgComponent from "../../../components/PkgComp";
import {
  dataOrderQuery,
  getOrderIdPackQuery,
  getOrderIdPickQuery,
} from "../../../gql/gql";
import ModalOrder from "./Modal/Modal";
import ModalCheckPickupTest from "./ModalCheckPickupTest/ModalCheckPickupTest";
import styles from "./styles.module.scss";
type propsType = {
  className?: String;
  pkgs?: Array<{
    ID: String;
    status?: String;
    deliveryDate?: Date;
  }>;
  setLines: any;
};

function PkgFilter(props: propsType) {
  const { setLines } = props;
  // const [orderIDState, setOrderIDState] = useState<any>([]);
  const [openModalPick, setOpenModalPick] = useState(false);
  const [openModalPack, setOpenModalPack] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModalPick = () => setOpenModalPick(true);
  const handleCloseModalPick = () => setOpenModalPick(false);
  const handleOpenModalPack = () => setOpenModalPack(true);
  const handleCloseModalPack = () => setOpenModalPack(false);
  const [orderDataState, setOrderDataState] = useState<any>([]);
  const [orderSelectedData, setOrderSelectedData] = useState<any>([]);

  const { data: dataPick = {}, refetch: setOrderIDPickState } =
    useQuery(getOrderIdPickQuery);
  const { data: dataPack = {}, refetch: setOrderIDPackState } =
    useQuery(getOrderIdPackQuery);
  const [getDataOrder] = useLazyQuery(dataOrderQuery);

  const dataOrderPick = dataPick?.partner_products?.map((item: any) => {
    return {
      ...item,
      status: "pickup",
    };
  });

  const dataOrderPack = dataPack?.partner_products?.map((item: any) => {
    return {
      ...item,
      status: "pack",
    };
  });

  const dataOrder = dataOrderPick?.concat(dataOrderPack) || [];
  const orderIDState = dataOrder || [];

  const onClickHandler = (data: any) => {
    setLoading(true);
    const status = data?.status;
    setOrderSelectedData(data);
    const id = data?.order_id;
    if (status === "pickup") {
      handleOpenModalPick();
    } else if (status === "pack") {
      handleOpenModalPack();
    }
    getDataOrder({
      variables: {
        orderId: id,
      },

      onCompleted(data) {
        const orderDataRes = data.partner_warehouse_groups;
        setOrderDataState(orderDataRes);
        setLoading(false);
      },
    });
  };

  return (
    <div className={props.className + " "}>
      <h3 className={styles.title}>Đơn hàng cần xử lý</h3>
      <div className="flex justify-end gap-4 align-middle cursor-pointer"></div>
      <div className={styles.pkgContainer + " flex-col flex gap-4"}>
        {orderIDState?.map((pkg: any, i: number) => {
          return (
            <div className="flex justify-between" key={i}>
              <PkgComponent
                className="grow"
                status={pkg?.status}
                ID={pkg?.order_id}
                onClickHandler={() => onClickHandler(pkg)}
              />
            </div>
          );
        })}
        <ModalOrder
          orderDataState={orderDataState}
          orderSelectedData={orderSelectedData}
          className="grow"
          status={orderSelectedData?.status}
          ID={orderSelectedData?.order_id}
          deliveryDate={orderSelectedData?.created_at}
          handleClose={handleCloseModalPick}
          open={openModalPick}
          setOrderIDPickState={setOrderIDPickState}
          setOrderIDPackState={setOrderIDPackState}
          setLines={setLines}
          loading={loading}
        />
        <ModalCheckPickupTest
          orderSelectedData={orderSelectedData}
          orderDataState={orderDataState}
          open={openModalPack}
          setOpen={setOpenModalPack}
          setLines={setLines}
          setOrderIDPickState={setOrderIDPickState}
          setOrderIDPackState={setOrderIDPackState}
        />
      </div>
    </div>
  );
}

export default PkgFilter;
