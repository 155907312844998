import { useState } from "react";
import ModalOut from "../ModalOut/modalOut";
import globalStyles from "../../globalStyles/globalStyles.module.scss";
import styles from "./styles.module.scss";

type propsType = {
  currentValue: {
    key: String;
    value: any;
  };
  validateFn?: any;
  warning?: string;
  setClose: any;
  setValue: any;
};

function ValueModal(props: propsType) {
  const updateData = () => {
    props.setValue(value);
    props.setClose((prev: any) => {
      return { ...prev, state: false };
    });
  };

  const applyHandler = () => {
    let validate = props.validateFn && props.validateFn(value);
    if (props.validateFn && !validate)
      setWarning(props.warning ? props.warning : "");
    else updateData();
  };
  const [isWarning, setWarning] = useState("");
  const [value, setValue] = useState(props.currentValue.value);

  return (
    <div className="fixed text-black w-screen h-screen top-0 left-0 flex items-center justify-center z-30">
      <ModalOut
        className="bg-[#69696910]"
        onClick={() => props.setClose(false)}
      />
      <div className={globalStyles.modalContainer}>
        <h2 className={globalStyles.title}>Thay đổi giá trị</h2>
        <p className="">{props.currentValue.key}</p>
        <input
          type="number"
          onChange={(e) => setValue(e.target.value)}
          placeholder={props.currentValue.value}
          autoFocus
          className={styles.input}
        />
        {isWarning && (
          <p className={globalStyles.warningValidate}>{isWarning}</p>
        )}
        <div className="flex gap-4 mt-6">
          <button
            autoFocus
            onClick={applyHandler}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                applyHandler();
              }
            }}
            className={globalStyles.button}
          >
            OK
          </button>
          <button
            onClick={() => props.setClose(false)}
            className={`${globalStyles.button} ${globalStyles.border}`}
          >
            Hủy
          </button>
        </div>
      </div>
    </div>
  );
}

export default ValueModal;
