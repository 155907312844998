import { icon } from "../../assets/imgGetter";
import styles from "./styles.module.scss";
type pkgProps = {
  className?: String;
  status?: String;
  ID: string;
  onClickHandler?: any;
};

function PkgComponent(props: pkgProps) {
  const { status, onClickHandler, className, ID } = props;
  const _icon = (status: any) => {
    switch (status) {
      case "pickup":
        return icon.pkgStatus.pickup;
      case "cancel":
        return icon.pkgStatus.cancel;
      case "issue":
        return icon.pkgStatus.issue;
      case "done":
        return icon.pkgStatus.done;
      case "delay":
        return icon.pkgStatus.delay;
      default:
        return icon.pkgStatus.issue;
    }
  };
  return (
    <div
      onClick={onClickHandler}
      className={className + " flex gap-4 cursor-pointer items-center"}
    >
      <img src={_icon(status)} alt="" className={styles.status} />
      <div>
        <h3 className={styles.title}>{`# ${ID}`}</h3>
        {status === "pickup" ? (
          <p className={styles.date}>Đang chờ pickup</p>
        ) : (
          <p className={styles.date}>Đang chờ đóng gói</p>
        )}
      </div>
    </div>
  );
}

export default PkgComponent;
