import { useQuery } from "@apollo/client";
import { log } from "console";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddRackPanel from "../../components/AddLinePanel/AddLinePanel";
import Map from "../../components/Map/Map";
import {
  getDataSlotQuery,
  partnerWarehouseGroupsQuery,
  totalLineQuery,
  totalOrderQuery,
  totalOrderStatusQuery,
} from "../../gql/gql";
import { IFragment, ILine, IShelf } from "../../interface/data";
import { partnerSelector } from "../../store/reducers/partner";
import homeStyles from "./homeStyles.module.scss";
import PkgFilter from "./PkgFilter";

type homePropsType = {};

function Home(props: homePropsType) {
  const partner = useSelector(partnerSelector);

  const { data: typeFree } = useQuery(getDataSlotQuery, {
    variables: {
      status: 1,
      warehouseId: partner.id,
    },
  });

  const { data: typeAssign } = useQuery(getDataSlotQuery, {
    variables: {
      status: 2,
      warehouseId: partner.id,
    },
  });

  const { data: typePick } = useQuery(getDataSlotQuery, {
    variables: {
      status: 3,
      warehouseId: partner.id,
    },
  });

  const { data: typePack } = useQuery(getDataSlotQuery, {
    variables: {
      status: 4,
      warehouseId: partner.id,
    },
  });

  const slotFree =
    typeFree?.partner_warehouse_slots_aggregate?.aggregate?.count || 0;

  const slotAssign =
    typeAssign?.partner_warehouse_slots_aggregate?.aggregate?.count || 0;

  const slotPick =
    typePick?.partner_warehouse_slots_aggregate?.aggregate?.count || 0;

  const slotPack =
    typePack?.partner_warehouse_slots_aggregate?.aggregate?.count || 0;

  const [isOpenPanel, setOpenPanel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data = {}, refetch: setLines } = useQuery(
    partnerWarehouseGroupsQuery,
    {
      variables: {
        warehouseCode: partner?.code,
      },

      onCompleted: (data) => {
        if (!data.line?.length) {
          setOpenPanel(true);
        }
      },
    }
  );

  let location = useLocation();
  useEffect(() => {
    setLines();
  }, [location, setLines]);

  const lines = data.line || [];

  console.log("lines", lines);

  const lineArray = lines.map((line: ILine) => line);

  const allFragmentArray = lineArray
    .map((line: ILine) => line.fragments)
    .flat();

  const allShelfArray = allFragmentArray
    .map((frag: IFragment) => frag.shelves)
    .flat();

  const allCapacity = allShelfArray
    .map((shelf: IShelf) => shelf.capacity)
    .reduce((a: any, b: any) => a + b, 0);

  const { data: totalOrderRes } = useQuery(totalOrderQuery);

  const { data: orderStatusPickRes } = useQuery(totalOrderStatusQuery, {
    variables: {
      status: 3,
    },
  });

  const { data: orderStatusPackRes } = useQuery(totalOrderStatusQuery, {
    variables: {
      status: 4,
    },
  });

  const orderStatusPick =
    orderStatusPickRes?.partner_products_aggregate?.aggregate?.count || 0;

  const orderStatusPack =
    orderStatusPackRes?.partner_products_aggregate?.aggregate?.count || 0;

  const totalOrder =
    totalOrderRes?.partner_products_aggregate?.aggregate?.count || 0;

  console.log(partner);
  const { data: totalLineRes } = useQuery(totalLineQuery, {
    variables: {
      warehouseId: partner?.id,
    },
  });

  const totalLine =
    totalLineRes?.partner_warehouse_groups_aggregate?.aggregate?.count || 0;

  return (
    <>
      {isOpenPanel && (
        <div className="summary mb-6 flex gap-6 md:flex-row flex-col">
          <AddRackPanel
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            getPartnerpartnerWarehouseGroups={() => {}}
            setClose={setOpenPanel}
            unCloseable={true}
            setLines={setLines}
            lines={lines}
          />
        </div>
      )}

      <div className="summary mb-6 flex gap-6 md:flex-row flex-col">
        <div
          className={
            homeStyles.sumItem + " w-full p-8 xl:p-12 bg-cyan-50 rounded"
          }
        >
          <div className="flex w-full h-full justify-between gap-10">
            <div className="flex flex-col justify-center">
              <p>Tổng số dãy</p>
              <div className="text-[3rem] font-bold">{totalLine} dãy</div>
            </div>
            <div className="flex justify-center flex-col "></div>
          </div>
        </div>
        <div
          className={
            homeStyles.sumItem + " w-full p-8 xl:p-12 bg-cyan-50 rounded"
          }
        >
          <div className="flex h-full w-full justify-between gap-10">
            <div className="flex flex-col justify-center">
              <p>Tổng số móc</p>

              {/* Need improve! */}
              <div className="text-[3rem] font-bold">{allCapacity} móc</div>
            </div>
            <div className="flex justify-center flex-col ">
              <p>{`${slotFree} móc trống`}</p>
              <p>{`${slotAssign} móc đã treo`}</p>
              <p>{`${slotPick} móc đã có đơn đặt hàng`}</p>
              <p>{`${slotPack} móc đang được đóng gói`}</p>
            </div>
          </div>
        </div>
        <div
          className={
            homeStyles.sumItem + " w-full p-8 xl:p-12 bg-cyan-50 rounded"
          }
        >
          <div className="flex h-full w-full justify-between gap-10">
            {/* TICH HOP */}
            <div className="flex flex-col justify-center">
              <p>Tổng số đơn hàng</p>
              <div className="text-[3rem] font-bold">{totalOrder} đơn</div>
            </div>
            <div className="flex justify-center flex-col ">
              <p>{orderStatusPick} đơn hàng cần xử lý</p>
              <p>{orderStatusPack} đơn cần đóng gói</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mapAndFilterFn flex flex-col gap-6 md:flex-row-reverse">
        <Map setLines={setLines} lines={lines} className="bg-white order-2" />
        <PkgFilter
          setLines={setLines}
          className="order-1 rounded p-8 md:min-w-[calc(100%/3-10px)] min-w-full bg-white"
        />
      </div>
    </>
  );
}

export default Home;
