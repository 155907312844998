import { createSlice } from "@reduxjs/toolkit";

const currentPageSlice = createSlice({
  name: "currentPage",
  initialState: {
    currentPage: "home",
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

// Reducer
const currentPageReducer = currentPageSlice.reducer;

// Selector
export const currentPageSelector = (state) =>
  state.currentPageReducer.currentPage;

// Action export
export const { setPage } = currentPageSlice.actions;
export default currentPageReducer;
