import styles from "./LineName.module.scss";

export interface LineNameProps {
  index: number;
  handleSelectedLine: any;
  line: any;
  icon: any;
  fragmentData: any;
  optionValue: any;
  selectedLine: any;
}

const LineName = (props: LineNameProps) => {
  const {
    index,
    handleSelectedLine,
    line,
    icon,
    fragmentData,
    optionValue,
    selectedLine,
  } = props;

  const slotInFragment = fragmentData?.[0]?.partner_warehouse_groups
    ?.map((item: any) => {
      return item.partner_warehouse_slots.map((item: any) => {
        return item.code;
      });
    })
    ?.flat(Infinity);

  const compareArray = (arr1: any, arr2: any) => {
    return arr1?.every((item: any) => arr2?.includes(item));
  };

  return (
    <div
      className={`${styles.lineWrap} ${
        selectedLine === index ? styles.active : ""
      }`}
      key={line.id}
      onClick={() => handleSelectedLine(index)}
    >
      <p className={`${styles.shelfTitle} ${styles.lineListItem}`}>
        {line.code}
      </p>
      {<img src={icon.checkAll} alt="checkAll" />}
    </div>
  );
};

export default LineName;
