import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { getPartnerWarehouseQuery } from "../gql/gql";
import { setPartner } from "../store/reducers/partner";

export default function FetchPartner(props) {
  
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(getPartnerWarehouseQuery);

  if (loading) return <></>;
  if (error) console.log(error);

  if (data) {
    dispatch(setPartner(data.partner_warehouses[0]));
  }

  return <>{props.children}</>;
}
