import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import styles from "./SlotMapPickup.module.scss";

export interface SlotsMapProps {
  className?: string;
  icon: any;
  shelfID: any;
  shelfNameState: any;
  setOptionValue?: any;
  orderDataState: any;
  dataCheckedInput: any;
  selectedLine: any;
}

const SlotMapPickup = (props: SlotsMapProps) => {
  const {
    icon,
    shelfID,
    shelfNameState,
    orderDataState,
    dataCheckedInput,
    setOptionValue,
    selectedLine,
  } = props;

  // const { data: responsePartnerWarehouseSlots } = useQuery(
  //   partnerWarehouseSlotsQuery,
  //   {
  //     variables: {
  //       shelfId: shelfID,
  //     },
  //   }
  // );

  const allSlotNeedPick = orderDataState
    .map((item: any) => {
      return item.partner_warehouse_groups.map((item: any) => {
        return item.partner_warehouse_groups.map((item: any) => {
          return item.partner_warehouse_slots.map((item: any) => {
            return item.code;
          });
        });
      });
    })
    .flat(Infinity);

  const [shelfInFragment, setShelfInFragment] = useState<any>([]);

  useEffect(() => {
    const shelfInFragment = orderDataState?.map((item: any) => {
      return item.partner_warehouse_groups.map((item: any) => {
        return item;
      });
    })?.[selectedLine];
    setShelfInFragment(shelfInFragment);
  }, [orderDataState, selectedLine, shelfID, shelfNameState]);

  const indexFragment = shelfInFragment?.findIndex((item: any) => {
    return item.code?.split("-")?.[1] === shelfNameState?.split("-")?.[1];
  });

  const slotsInShelf = shelfInFragment?.[
    indexFragment
  ]?.partner_warehouse_groups?.filter((item: any) => {
    return item.id === shelfID;
  });

  // console.log("slotsInShelf", slotsInShelf?.[0]?.partner_warehouse_slots);
  // console.log("shelfID", shelfID);

  // console.log(shelfInFragment);
  // console.log(shelfNameState);

  // console.log("orderDataState", orderDataState);

  // console.log(
  //   "responsePartnerWarehouseSlots",
  //   responsePartnerWarehouseSlots?.partner_warehouse_slots
  // );

  const checkSlotNeedPick = (slotID: any) => {
    return allSlotNeedPick.includes(slotID);
  };

  // let slots = responsePartnerWarehouseSlots?.partner_warehouse_slots;
  let slots = slotsInShelf?.[0]?.partner_warehouse_slots;
  // console.log("slots", slots);

  return (
    <div className={styles.shelfWrap}>
      <div className={styles.shelfTitle}>
        {/* Xoá "-" và "S" */}
        {"Kệ " + shelfNameState?.split("-")[2].replace("S", "")}
      </div>
      <div
        className={
          "shelves overflow-y-scroll max-h-[50vh] grid gap-x-[2rem] gap-y-[.5rem] gap-y-4=2"
        }
      >
        {slots?.length ? (
          slots?.map((slot: any, index: number) => {
            return (
              <div className="flex items-center" key={slot.id + index}>
                <div className={styles.statusNotify}>
                  <div className={styles.status}>
                    {checkSlotNeedPick(slot.code) && (
                      <img src={icon.slotStatus.notice} alt="notice" />
                    )}
                  </div>
                  {checkSlotNeedPick(slot.code) ? (
                    <img
                      src={icon.slotStatus.needPickup}
                      alt="needPickup"
                      title="needPickup"
                    />
                  ) : (
                    <img
                      src={icon.slotStatus.noNeedPickup}
                      alt="noNeedPickup"
                      title="noNeedPickup"
                    />
                  )}
                </div>
                <div className={styles.slotCode}>
                  <p
                    className="ml-4 min-w-[100%] text-3xl"
                    onClick={() => console.log(slot.code)}
                  >
                    {slot.code}
                  </p>
                </div>
                <div className="input-wrap w-[15%] flex items-center h-[100%]">
                  {checkSlotNeedPick(slot.code) && (
                    <Checkbox
                      checked={dataCheckedInput.includes(slot.code)}
                      onChange={() => {
                        setOptionValue((prev: any) => {
                          if (prev.includes(slot.code)) {
                            return prev.filter(
                              (item: any) => item !== slot.code
                            );
                          } else {
                            return [...prev, slot.code];
                          }
                        });
                      }}
                      classes={{ root: styles.checkbox }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center	">Không có móc tương ứng</p>
        )}
      </div>
    </div>
  );
};

export default SlotMapPickup;
