import { useQuery } from "@apollo/client";
import { Fragment, useEffect } from "react";
import { partnerWarehouseSlotsQuery } from "../../gql/gql";
import styles from "./SlotsMap.module.scss";
export interface SlotsMapProps {
  className?: string;
  icon: any;
  shelf: any;
  valueFilter?: string;
  setOptionValue?: any;
  valueCheckbox?: any;
}

const SlotsMap = (props: SlotsMapProps) => {
  const { icon, shelf, valueFilter } = props;
  const { data: responsePartnerWarehouseSlots, refetch } = useQuery(
    partnerWarehouseSlotsQuery,
    {
      variables: {
        shelfId: shelf?.id,
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [shelf?.id]);

  // console.log("responsePartnerWarehouseSlots", responsePartnerWarehouseSlots);

  let slots = responsePartnerWarehouseSlots?.partner_warehouse_slots;

  if (valueFilter) {
    slots = slots?.filter((slot: any) => slot.status === parseInt(valueFilter));
  }

  return (
    <div className={styles.shelfWrap}>
      <div className={styles.shelfTitle}>
        {/* Xoá "-" và "S" */}
        {"Kệ " + shelf.name.split("-")[2].replace("S", "")}
      </div>
      <div
        className={
          "shelves overflow-y-scroll max-h-[82vh] px-8 grid  gap-x-[2rem] gap-y-[.5rem] gap-y-4=2"
        }
      >
        {slots?.length ? (
          slots?.map((slot: any, index: number) => {
            return (
              <div className="flex" key={slot.id}>
                <Fragment>
                  {slot.status === 1 ? (
                    <img
                      src={icon.slotStatus.empty}
                      alt="empty"
                      title="empty"
                    />
                  ) : slot.status === 2 ? (
                    <img
                      src={icon.slotStatus.available}
                      alt="available"
                      title="available"
                    />
                  ) : slot.status === 3 ? (
                    <img
                      src={icon.slotStatus.allocated}
                      alt="allocated"
                      title="allocated"
                    />
                  ) : (
                    <img src={icon.slotStatus.pack} alt="pack" title="pack" />
                  )}
                </Fragment>
                <p className="ml-4 min-w-[50%]">{slot.code}</p>
                {/* <div className="input-wrap">
                  <input
                    title="Bấm để chọn pickup hàng"
                    type="checkbox"
                    className="ml-4"
                    onChange={() => valueCheckbox(slot.id)}
                  />
                </div> */}
              </div>
            );
          })
        ) : (
          <p>Không có móc tương ứng</p>
        )}
      </div>
    </div>
  );
};

export default SlotsMap;
