import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Link } from "react-router-dom";
import imgGetter from "../../assets/imgGetter";
import { IFragment, ILine, IShelf } from "../../interface/data";
import {
  selectedLine,
  selectedShelf,
} from "../../store/reducers/selectedItems";
import { showInfoShelf } from "../../store/reducers/warehouse";
import styles from "./map.module.scss";

type mapPropsType = {
  className?: String;
  filter?: String;
  clickRack?: any;
  clickFrag?: any;
  lines: ILine[];
  setLines: any;
};

function Map(props: mapPropsType) {
  const { lines } = props;
  const dispatch = useDispatch();
  const selectedItems = useSelector(
    (state: any) => state.selectedItemsReducer
  ).selectedItems;

  const selectedLineID = selectedItems.lineID;
  const selectedShelfID = selectedItems.shelfID;

  const activeLine = (id: String) => {
    dispatch(selectedLine(id));
  };

  const activeShelf = (id: String) => {
    dispatch(selectedShelf(id));
  };

  let maxFragment: any = 0;
  // Get the max Fragment for header render
  lines?.forEach((line: any, i: any) => {
    const newArr = line.fragments;
    maxFragment = maxFragment < newArr.length ? newArr.length : maxFragment;
  });

  return (
    <div
      className={
        "p-8 w-full overflow-hidden bg-white " +
        props.className +
        " " +
        styles.mapComp
      }
    >
      <div className="header flex mb-8 flex-col justify-between items-center gap-6 sm:flex-row">
        <div className="headline">
          <h1 className={styles.title + " inline-block"}>Bản đồ kho hàng</h1>
          <Link to="/whm" className="cursor-pointer">
            <img
              src={imgGetter.icon.database.roundedColored}
              className=" ml-4 inline-block "
              alt=""
            />
          </Link>
        </div>
      </div>
      <div className={styles.mapContainer}>
        <table className={styles.mapTable}>
          <thead className={styles.mapThead}>
            <tr className="relative">
              <th className={styles.mapTh}> </th>
              {Array(maxFragment)
                .fill(0)
                .map((_, i) => (
                  <th className={styles.mapTh} key={`F${i}`}>{`F${i + 1}`}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {lines?.map((line: ILine, i: any) => (
              <Fragment key={i}>
                {
                  <tr
                    className={`${styles.mapTr} ${
                      props.clickRack ? "clickable" : " "
                    }`}
                    key={i}
                  >
                    <th
                      onClick={() => {
                        activeLine(line.code);
                        props.clickRack &&
                          props.clickRack((prev: any) =>
                            prev !== line.code ? line.code : ""
                          );
                      }}
                      title="Click to show detail"
                      className={`${styles.mapTd} ${styles.mapRackID} `}
                    >
                      <div
                        className={
                          (selectedLineID === line.code
                            ? styles.activeShelf
                            : "") + " px-3"
                        }
                      >
                        {line.code}
                      </div>
                    </th>
                    {
                      // Render
                      line?.fragments?.map((frag: IFragment, i: any) => (
                        <Fragment key={i}>
                          {
                            <td className={styles.mapTd} key={i}>
                              <div
                                className={`${styles.mapCellContainer} ${
                                  props.clickFrag ? "clickable" : ""
                                }`}
                              >
                                <div className={styles.mapFragmentMd}>
                                  {frag.shelves.map((shelf: IShelf, i: any) => (
                                    <Fragment key={i}>
                                      <div
                                        onClick={() => {
                                          activeShelf(shelf?.code);
                                          dispatch(showInfoShelf(shelf));
                                          props.clickRack &&
                                            props.clickRack("");
                                        }}
                                        className={`${styles.mapLine} ${
                                          selectedShelfID === shelf.code
                                            ? styles.activeShelf
                                            : ""
                                        }`}
                                        title="Click to show status"
                                      >
                                        {shelf.code}
                                      </div>
                                    </Fragment>
                                  ))}
                                </div>
                                <div className="px-4 flex flex-col gap-4 items-center justify-between md:flex-row"></div>
                              </div>
                            </td>
                          }
                        </Fragment>
                      ))
                    }
                    {
                      // Fake frag
                      maxFragment > line.fragments.length && (
                        <>
                          {Array(maxFragment - line.fragments.length)
                            .fill(0)
                            .map((_, i) => (
                              <td key={i}></td>
                            ))}
                        </>
                      )
                    }
                  </tr>
                }
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="description">
        <span className="inline-block mr-12">
          <div className="square w-6 h-6 inline-block mr-6 bg-[#B2F064]"></div>
          Có đơn hàng
        </span>
        <span className="inline-block mr-12">
          <div className="square w-6 h-6 inline-block mr-6 bg-[#64A44D]"></div>
          Đã đầy
        </span>
        <span className="inline-block mr-12">
          <div className="rounded-full w-6 h-6 inline-block mr-6 bg-[#FF0000]"></div>
          Đơn hàng được chọn
        </span>
      </div>
    </div>
  );
}

export default Map;
