import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { loginQuery } from "../../../gql/gql";
import { LoginQuery } from "../../../gql/types";

type Inputs = {
  username: string;
  password: string;
};

export interface LoginProps {
  className?: string;
}

const Login = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit } = useForm<Inputs>();

  const [handler, { loading }] = useLazyQuery(loginQuery);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.href = "/";
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (dataInput) => {
    handler({
      variables: { req: dataInput },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      onCompleted: (data: LoginQuery) => {
        localStorage.setItem("token", data.login!.token);
        localStorage.setItem("refreshToken", data.login!.refreshToken);

        // redirect to home
        window.location.href = "/";
      },
    });
  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full max-w-[350px] p-6 m-auto bg-white rounded-md shadow-xl shadow-[#1f3d5e]/40 ring ring-2 ring-[#1f3d5e] lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-[#1f3d5e] uppercase decoration-wavy">
          WMS
        </h1>
        <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <label
              // for="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              type="email"
              className="block w-full px-4 py-2 mt-2 text-[#1f3d5e] bg-white border rounded-md focus:border-[#1f3d5e] focus:ring-[#1f3d5e] focus:outline-none focus:ring focus:ring-opacity-40"
              {...register("username", { required: true })}
            />
          </div>
          <div className="mb-2">
            <label
              // for="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Password
            </label>
            <input
              type="password"
              className="block w-full px-4 py-2 mt-2 text-[#1f3d5e] bg-white border rounded-md focus:border-[#1f3d5e] focus:ring-[#1f3d5e] focus:outline-none focus:ring focus:ring-opacity-40"
              {...register("password", { required: true })}
            />
          </div>
          <div className="mt-6">
            <button
              disabled={loading}
              className="disabled:opacity-40 w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#1f3d5e] rounded-md hover:bg-[#1f3d5e] focus:outline-none focus:bg-[#1f3d5e]"
            >
              LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
