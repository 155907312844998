import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Map from "../../components/Map/Map";
import { partnerWarehouseGroupsQuery } from "../../gql/gql";
import { partnerSelector } from "../../store/reducers/partner";
import LineInfo from "./LineInfo";
import RackList from "./LinesList";

type warehousePropsType = {};

function Warehouse(props: warehousePropsType) {
  const partner = useSelector(partnerSelector);

  const { data = {}, refetch: setLines } = useQuery(
    partnerWarehouseGroupsQuery,

    {
      variables: {
        warehouseCode: partner?.code,
      },
    }
  );

  let location = useLocation();
  useEffect(() => {
    setLines();
  }, [location]);

  const lines = data.line || [];

  const navigate = useNavigate();
  let initLine: string = "";
  const [lineSelected, setSelectLine] = useState(initLine);

  useEffect(() => {
    if (lines.length === 0) navigate("/", { replace: true });
  }, [lines, navigate]);

  return (
    <>
      <div className="flex gap-8 mb-8">
        <Map setLines={setLines} lines={lines} clickRack={setSelectLine} />
        <LineInfo
          lines={lines}
          setLine={setSelectLine}
          setLines={setLines}
          lineCode={lineSelected}
        />
      </div>
      <div className="flex gap-8">
        <RackList
          getPartnerpartnerWarehouseGroups={() => {}}
          setLine={setSelectLine}
          setLines={setLines}
          lines={lines}
        />
      </div>
    </>
  );
}

export default Warehouse;
