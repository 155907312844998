import { Navigate } from "react-router-dom";

export interface CheckLoginProps {
  children?: any;
}

const CheckLogin = (props: CheckLoginProps) => {
  const { children } = props;
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default CheckLogin;
