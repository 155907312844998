import add from "./icon/add.svg";
import box from "./icon/box.svg";
import caretDown from "./icon/caret-down.svg";
import caretDownWhite from "./icon/caret-downWhite.svg";
import caretLeft from "./icon/caret-left.svg";
import checkbox from "./icon/checkbox.svg";
import checkboxChecked from "./icon/checkbox_checked.svg";
import checkboxSquare from "./icon/checkbox_square.svg";
import databaseRoundedColored from "./icon/databaseRounded.svg";
import expand from "./icon/expand.svg";
import filter from "./icon/filter.svg";
import home from "./icon/home.svg";
import homeWhite from "./icon/homeWhite.svg";
import lowerColored from "./icon/lowerColored.svg";
import lowerWhite from "./icon/lowerWhite.svg";
import slotNotice from "./icon/notice.svg";
import pkgCancel from "./icon/pkgCancel.svg";
import pkgCheck from "./icon/pkgCheck.svg";
import pkgDelay from "./icon/pkgDelay.svg";
import pkgIssue from "./icon/pkgIssue.svg";
import pkgPickup from "./icon/pkgPickup.svg";
import search from "./icon/search.svg";
import slotAllocated from "./icon/slotAllocated.svg";
import slotAvailable from "./icon/slotAvailable.svg";
import slotEmpty from "./icon/slotEmpty.svg";
import noNeedPickup from "./icon/slotNoPickup.svg";
import slotPack from "./icon/slotPack.svg";
import needPickup from "./icon/slotPickup.svg";
import upperColored from "./icon/upperColored.svg";
import upperWhite from "./icon/upperWhite.svg";

import checkAll from "./icon/check_all.svg";
import close from "./icon/close.svg";
import edit from "./icon/edit.svg";
import hamburger from "./icon/hamburger.svg";
import settings from "./icon/settings.svg";
import trash from "./icon/trash.svg";
import trashWhite from "./icon/trashWhite.svg";
import whStatusAllocated from "./icon/whStatusAllocated.svg";
import whStatusDefault from "./icon/whStatusDefault.svg";
import whStatusFull from "./icon/whStatusFull.svg";
import whTotal from "./icon/whTotal.svg";
import whUsed from "./icon/whUsed.svg";
import switchWMS from "./icon/switchWMS.svg";

const imgGetter = {
  icon: {
    hamburger,
    edit,
    switchWMS,
    checkAll,
    trash: {
      default: trash,
      white: trashWhite,
    },
    close,
    settings,
    wh: {
      status: {
        default: whStatusDefault,
        allocated: whStatusAllocated,
        full: whStatusFull,
      },
      total: whTotal,
      used: whUsed,
    },
    home: {
      color: home,
      colorless: homeWhite,
    },
    slotStatus: {
      allocated: slotAllocated,
      available: slotAvailable,
      empty: slotEmpty,
      pack: slotPack,
      notice: slotNotice,
      needPickup: needPickup,
      noNeedPickup: noNeedPickup,
    },
    caret: {
      down: {
        white: caretDownWhite,
        default: caretDown,
      },
      left: {
        default: caretLeft,
      },
    },
    expand,
    filter,
    add,
    box,
    search,
    lower: {
      white: lowerWhite,
      colored: lowerColored,
    },
    upper: {
      white: upperWhite,
      colored: upperColored,
    },
    database: {
      roundedColored: databaseRoundedColored,
    },
    checkbox: {
      default: checkbox,
      checked: checkboxChecked,
      square: checkboxSquare,
    },
    pkgStatus: {
      done: pkgCheck,
      pickup: pkgPickup,
      delay: pkgDelay,
      issue: pkgIssue,
      cancel: pkgCancel,
    },
  },
};

export default imgGetter;
export const icon = imgGetter.icon;
