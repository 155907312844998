import { useMutation } from "@apollo/client";
import { Box, Button, Modal } from "@mui/material";
import { useSnackbar } from "notistack";

import { Fragment, useState } from "react";
import { icon } from "../../../../assets/imgGetter";
import { packProductsQuery, pickupProductQuery } from "../../../../gql/gql";
import { PickupProductMutation } from "../../../../gql/types";
import styles from "./styles.module.scss";

export interface ModalCheckPickupProps {
  className?: string;
  openChild: boolean;
  setOpenChild: any;
  handleOpen: any;
  optionValue: Array<String>;
  slotInLine: any;
  orderId: any;
  setOrderIDPickState: any;
  handleClose: any;
  status: String;
  setOrderIDPackState: any;
  setLines: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  outline: "none",
};

const ModalCheckPickup = (props: ModalCheckPickupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    openChild,
    setOpenChild,
    optionValue,
    slotInLine,
    orderId,
    setOrderIDPickState,
    handleClose,
    status,
    setOrderIDPackState,
    setLines,
  } = props;

  const [pickupOrder] = useMutation(pickupProductQuery);
  const [packProducts] = useMutation(packProductsQuery);
  const [loading, setLoading] = useState(false);

  const slotNotPickup = slotInLine?.filter((item: any) => {
    return !optionValue.includes(item.code) && item;
  });

  const handleCloseModal = () => {
    setOpenChild(false);
  };

  const handleAcceptPickup = async () => {
    setOpenChild(false);
    setLoading(true);
    if (status === "pickup") {
      await pickupOrder({
        variables: {
          order_id: orderId,
          slot_codes: optionValue,
        },

        onCompleted(data: PickupProductMutation) {
          enqueueSnackbar("Pickup successfully", {
            variant: "success",
          });
          setOrderIDPickState();
          setOrderIDPackState();
          setLines();
          handleClose();
          setLoading(false);
        },

        onError(error) {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          setLoading(false);
        },
      });
    } else {
      await packProducts({
        variables: {
          order_id: orderId,
          slot_codes: optionValue,
        },
        onCompleted(data) {
          enqueueSnackbar("Pack successfully", {
            variant: "success",
          });
          setOrderIDPickState();
          setOrderIDPackState();
          setLines();
          handleClose();
          setLoading(false);
        },

        onError(err) {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
          setLoading(false);
        },
      });
    }
  };

  return (
    <Modal
      hideBackdrop
      open={openChild}
      onClose={handleCloseModal}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width: 200 }}>
        {status === "pickup" ? (
          <h2 className="child-modal-title font-semibold text-xl">
            Xác nhận đã lấy hàng
          </h2>
        ) : (
          <h2 className="child-modal-title font-semibold text-xl">
            Xác nhận đã đóng gói
          </h2>
        )}

        {slotNotPickup?.length ? (
          <Fragment>
            {status === "pickup" ? (
              <div className="child-modal-description my-[5px] font-medium">
                Các móc chưa được lấy:{" "}
              </div>
            ) : (
              <div className="child-modal-description my-[5px] font-medium">
                Các móc chưa được đóng gói:{" "}
              </div>
            )}
            <div className={styles.slotWrap}>
              {slotNotPickup?.map((item: any, index: number) => {
                return (
                  <div className="flex items-center" key={item.id}>
                    <div className={styles.statusNotify}>
                      <img
                        src={icon.slotStatus.needPickup}
                        alt="empty"
                        title="empty"
                      />
                    </div>
                    <div className={styles.slotCode}>
                      <p className="ml-4 min-w-[100%] text-xl my-[5px]">
                        {item.code}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fragment>
        ) : status === "pickup" ? (
          <div className="child-modal-description my-[5px] font-medium">
            Đã lấy đủ tất cả móc
          </div>
        ) : (
          <div className="child-modal-description my-[5px] font-medium">
            Đã đóng gói tất cả móc
          </div>
        )}
        <div className={styles.buttonWrap}>
          <Button onClick={handleCloseModal}>Huỷ bỏ</Button>
          {!slotNotPickup?.length && (
            <Button onClick={handleAcceptPickup} disabled={loading}>
              Xác nhận
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalCheckPickup;
