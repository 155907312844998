import { useMutation } from "@apollo/client";
import { log } from "console";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addGroupQuery, createPartnerWarehouseSlotsQuery } from "../../gql/gql";
import { IFragment, ILine, IShelf } from "../../interface/data";
import ModalOut from "../ModalOut/modalOut";
import PageLoading from "../PageLoading";
import Fragments from "./Fragments/Fragments";
import Shelfs from "./Shelfs/Shelfs";
import styles from "./styles.module.scss";
import Title from "./Title/Title";

type propsType = {
  setClose: any;
  unCloseable?: boolean;
  lines?: any;
  getPartnerpartnerWarehouseGroups: any;
  setLines: any;
  isLoading?: any;
  setIsLoading: any;
};

function AddLinePanel(props: propsType) {
  const { lines, setLines, isLoading, setIsLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  let lineInit: ILine = {
    id: "",
    code: "0",
    orderNumber: 1,
    name: "Dãy 0",
    priority: 1,
    capacity: 0,
    totalFragment: 1,
    totalShelf: 1,
    using: true,
    fragments: [
      {
        id: "",
        code: "L0-F0",
        name: "",
        orderNumber: 1,
        using: true,
        priority: 2,
        totalShelf: 1,
        capacity: 0,
        shelves: [
          {
            id: "",
            code: "L0-F0-S0",
            priority: 3,
            name: "",
            orderNumber: 1,
            using: true,
            capacity: 0,
          },
        ],
      },
    ],
  };
  const [lineState, updateLine] = useState(lineInit);

  const [createPartnerGroupLine] = useMutation(addGroupQuery);

  const [createPartnerGroupFragment] = useMutation(addGroupQuery);

  const [createPartnerGroupShelf] = useMutation(addGroupQuery);

  const [createPartnerWarehouseSlots] = useMutation(
    createPartnerWarehouseSlotsQuery
  );

  const currentProfile = useSelector(
    (state: any) =>
      state.warehouseReducer.warehouse.info.partner_warehouses[0].id
  );

  const parseToArr = (idShelf: string, capacity: number) => {
    const arr = [];

    for (let i = 0; i < capacity; i++) {
      arr.push(
        `${idShelf.split("-")[2].replace("S", "")}${i < 10 ? "0" : ""}${i}`
      );
    }

    return arr;
  };

  // Validate
  function validate() {
    if (lineState.code === "L0" || "")
      setWarning({ for: "LineID", content: "Dãy chưa có ID", status: true });

    if (warning.status) return;
    setWarning({ for: "", content: "", status: false });

    const capacityShelf = lineState.fragments.map((frag) => {
      return frag.shelves.map((shelf) => shelf.capacity);
    });

    const capacityShelfArr = capacityShelf.reduce((a, b) => a.concat(b), []);

    if (capacityShelfArr.includes(0)) {
      alert("Số lượng slot chưa được cấu hình");
      return;
    }

    const createLine = () => {
      setIsLoading(true);
      const orderNumber =
        lines?.length > 0 ? lines[lines.length - 1].orderNumber + 1 : 1;

      return createPartnerGroupLine({
        variables: {
          code: lineState?.code,
          name: lineState?.name,
          order_number: orderNumber,
          parent_partner_warehouse_group_id: "",
          partner_warehouse_id: currentProfile,
          slot: 1,
          status: "active",
          warehouse_group_type_id: "line",
        },
      });
    };
    const createFragmentFromIDResponse = async () => {
      try {
        const response = await createLine();
        if (response) {
          const arrCreatePartnerGroupFragmentPromise: Promise<void>[] = [];

          const idLine = response.data.createPartnerWarehouseGroup.id;
          if (idLine) {
            lineState?.fragments.forEach(async (frag: any, index) => {
              const a = async () => {
                const response = await createPartnerGroupFragment({
                  variables: {
                    code: frag?.code,
                    name: frag?.name,
                    order_number: index + 1 || 1,
                    parent_partner_warehouse_group_id: idLine,
                    partner_warehouse_id: currentProfile,
                    slot: 1,
                    status: "active",
                    warehouse_group_type_id: "fragment",
                  },
                });
                const arrCreatePartnerGroupShelfPromise: Promise<any>[] = [];
                if (response) {
                  const idFragment =
                    response.data.createPartnerWarehouseGroup.id;
                  if (idFragment) {
                    // tách ra 1 hàm riêng
                    frag?.shelves.forEach((shelf: any, index: any) => {
                      const a = async () => {
                        const response = await createPartnerGroupShelf({
                          variables: {
                            code: shelf?.code,
                            name: shelf?.name,
                            order_number: index + 1 || 1,
                            parent_partner_warehouse_group_id: idFragment,
                            partner_warehouse_id: currentProfile,
                            slot: shelf.capacity,
                            status: "active",
                            warehouse_group_type_id: "shelf",
                          },
                        });

                        if (response) {
                          const idShelf =
                            response.data.createPartnerWarehouseGroup.id;
                          if (idShelf) {
                            createPartnerWarehouseSlots({
                              variables: {
                                codes: parseToArr(shelf?.code, shelf.capacity),
                                partner_warehouse_group_id: idShelf,
                                partner_warehouse_id: currentProfile,
                              },
                            });
                          }
                        }
                      };
                      arrCreatePartnerGroupShelfPromise.push(a());
                    });
                  }
                }
                await Promise.all(arrCreatePartnerGroupShelfPromise);
              };
              arrCreatePartnerGroupFragmentPromise.push(a());
            });
          }
          await Promise.all(arrCreatePartnerGroupFragmentPromise);
        }
        ClosePanel(true);
        setLines();
        setIsLoading(false);
        enqueueSnackbar("Thêm dãy thành công", { variant: "success" });
      } catch (err: any) {
        setIsLoading(false);
        enqueueSnackbar("Tạo dãy thất bại: " + err.message, {
          variant: "error",
        });
      }
    };

    createFragmentFromIDResponse();
  }

  const [warning, setWarning] = useState({
    for: "LineID",
    status: true,
    content: "Dãy chưa có ID",
  });

  const [isClosing, ClosePanel] = useState(false);

  useEffect(() => {
    updateLine((prev) => {
      return { ...updateLineForm(prev) };
    });
  }, []);

  // Change the layout data
  const updateLineForm = (line: ILine) => {
    return line;
  };

  const updateLineCode = (line: ILine) => {
    line.code = `L${line.code.split("L").pop()}`;
    line.name = `Dãy ${line.code.split("L").pop()}`;
    line.fragments.forEach((fragment: IFragment) => {
      fragment.code = `${line.code}-F${fragment.code.split("-F").pop()}`;
      fragment.name = `Phân kệ ${fragment.code}`;
      fragment.shelves.forEach((shelf: IShelf) => {
        shelf.code = `${fragment.code}-S${shelf.code.split("-S").pop()}`;
        shelf.name = `Kệ ${shelf.code}`;
      });
    });

    return line;
  };

  function changeLineId(lineId: String) {
    updateLine((prev) => {
      let temp = prev;
      temp.code = lineId || "0";
      updateLineCode(temp);
      return { ...temp };
    });
  }

  function changeFragmentId(fragmentId: String, orderNumber: number) {
    updateLine((prev) => {
      let temp = prev;
      temp.fragments[orderNumber - 1].code = fragmentId || "0";
      updateLineCode(temp);
      return { ...temp };
    });
  }

  function changeShelfId(
    shelfId: String,
    fragmentON: number,
    orderNumber: number
  ) {
    updateLine((prev) => {
      let temp = prev;
      temp.fragments[fragmentON - 1].shelves[orderNumber - 1].code =
        shelfId || "0";
      updateLineCode(temp);
      return { ...temp };
    });
  }

  // Shelves feature

  function addShelf(parentFragment: IFragment) {
    let newShelf: IShelf = {
      id: "",
      orderNumber: parentFragment.totalShelf
        ? parentFragment.totalShelf + 1
        : 1,
      code: `${parentFragment.code}-S0`,
      name: `Kệ ${parentFragment.code}-S0`,
      priority: 3,
      using: true,
      capacity: 0,
    };

    updateLine((prev) => {
      let temp = prev;
      temp.fragments[parentFragment.orderNumber - 1].shelves.push(newShelf);
      temp = updateLineForm(temp);
      return { ...temp };
    });
  }

  function deleteShelf(fragment: IFragment, shelf: IShelf) {
    updateLine((prev) => {
      let temp = prev;
      let itemsBehide = prev.fragments[fragment.orderNumber - 1].shelves.slice(
        shelf.orderNumber
      );

      itemsBehide.forEach((item: IShelf) => {
        item.orderNumber--;
      });
      temp.fragments[fragment.orderNumber - 1].shelves.splice(
        shelf.orderNumber - 1,
        1
      );

      updateLineForm(temp);

      return { ...temp };
    });
  }

  function changeShelfCapacity(
    capacity: number,
    shelf: IShelf,
    fragment: IFragment
  ) {
    updateLine((prev) => {
      let temp = prev;
      fragment.shelves[shelf.orderNumber - 1].capacity = parseInt(
        capacity.toString()
      );
      fragment.capacity = fragment.shelves.reduce(
        (c: number, sh: IShelf) => sh.capacity + c,
        0
      );

      temp.capacity = temp.fragments.reduce(
        (c: number, f: IFragment) => f.capacity + c,
        0
      );
      return { ...temp };
    });
  }
  // Fragment feature
  function addFragment() {
    let newFragment: IFragment = {
      id: "",
      code: `L${lineState.code}-F0`,
      orderNumber: lineState.totalFragment ? lineState.totalFragment + 1 : 1,
      priority: 2,
      capacity: 0,
      using: true,
      name: `Phân kệ L${lineState.code}-F0`,
      totalShelf: 1,
      shelves: [
        {
          id: "",
          code: "0",
          orderNumber: 1,
          capacity: 0,
          priority: 3,
          using: true,
          name: "Kệ 1",
        },
      ],
    };

    updateLine((prev) => {
      let temp = prev;
      temp.fragments.push(newFragment);
      temp = updateLineForm(temp);
      temp = updateLineCode(temp);
      return { ...temp };
    });
  }

  function deleteFragment(fragment: IFragment) {
    updateLine((prev) => {
      if (prev.totalFragment === 1) return { ...prev };

      let temp = prev;
      let itemsBehide = prev.fragments.slice(fragment.orderNumber);

      itemsBehide.forEach((item: IFragment) => {
        item.orderNumber--;
      });
      temp.fragments.splice(fragment.orderNumber - 1, 1);

      updateLineForm(temp);

      return { ...temp };
    });
  }

  function toggleFragment(fragment: IFragment) {
    updateLine((prev) => {
      if (prev.totalFragment === 1 && fragment.using) return { ...prev };
      fragment.using = !fragment.using;

      return { ...prev };
    });
  }

  return (
    <div
      className={`${styles.rackPanel} ${isClosing ? styles.modalClose : " "}`}
      onAnimationEnd={() => (isClosing ? props.setClose(false) : () => {})}
    >
      {!props?.unCloseable && <ModalOut onClick={() => ClosePanel(true)} />}
      <PageLoading isLoading={isLoading}>
        <div className={styles.container}>
          <div className="mb-10">
            <Title unCloseable={props?.unCloseable} ClosePanel={ClosePanel} />
            {/* LineID */}
            <h3 className="uppercase font-semibold">
              ID dãy :
              <input
                className={styles.IDInp}
                placeholder="0000"
                onChange={(e) => {
                  setWarning((prev) =>
                    e.target.value !== ""
                      ? { for: "", status: false, content: "" }
                      : prev
                  );
                  changeLineId(e.target.value);
                }}
                type="text"
              />
            </h3>
            {warning.for === "LineID" && (
              <p className="warning text-orange-600">{warning.content}</p>
            )}
            {/* Line demonstration model */}
            <h3 className="uppercase mt-4 font-semibold">Mô hình dãy</h3>

            {/* Upper view -> Line: Fragments*/}
            <p>Từ trên xuống (Các phân kệ)</p>
            <Fragments
              lineState={lineState}
              changeFragmentId={changeFragmentId}
              toggleFragment={toggleFragment}
              addFragment={addFragment}
              deleteFragment={deleteFragment}
            />

            {/* Direct view ->  Line : [Shelves x Fragments] */}
            <p>Góc trực tiếp (Các kệ)</p>
            {/* Fragments shelves */}
            <Shelfs
              lineState={lineState}
              changeShelfId={changeShelfId}
              deleteShelf={deleteShelf}
              changeShelfCapacity={changeShelfCapacity}
              addShelf={addShelf}
            />

            {/* Summary */}
            <h3 className="uppercase mt-10 font-semibold">
              Tổng kết thông tin
            </h3>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>ID sào</p>#{lineState.id}
            </div>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>Số lượng phân kệ</p>
              {lineState.totalFragment?.toString()}
            </div>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>Tổng số kệ con</p>
              {lineState.totalShelf?.toString()}
            </div>
            <div className="flex gap-8">
              <p className={styles.infoLabel}>Tổng số lượng slot</p>
              {lineState.capacity?.toString()}
            </div>
          </div>

          <div className="modalBtn flex justify-between">
            <div></div>
            <div className="flex gap-4">
              <button onClick={() => validate()} className={`${styles.button}`}>
                Đồng ý
              </button>
              {!props?.unCloseable && (
                <button
                  onClick={() => ClosePanel(true)}
                  className={`${styles.button} ${styles.border}`}
                >
                  Hủy
                </button>
              )}
            </div>
          </div>
        </div>
      </PageLoading>
    </div>
  );
}

export default AddLinePanel;
