import { useState } from "react";
import { Outlet } from "react-router-dom";
import SearchBox from "../components/SearchBox";
import globalStyles from "../globalStyles/globalStyles.module.scss";

function PageSection() {
  const currentPage = window.location.pathname;
  

  const [searchValueInput, setSearchValueInput] = useState("");

  const title = () => {
    switch (currentPage) {
      case "/":
        return "DASHBOARD";
      case "/whm":
        return "NHÀ KHO";
    }
    return "";
  };

  let searchValue = {
    value: ["pkgId", "ItemID", "RackId", "ShelfId", "SlotId"],
    content: ["Mã đơn hàng", "Mã hàng", "Mã sào", "Mã kệ", "Mã móc"],
  };

  const handleSearch = (searchContent: string) => {
    console.log(searchContent, searchValueInput);
  };

  return (
    <div className="page relative px-[2rem] py-[2rem] text-[1.6rem] md:ml-[7rem]">
      <div className="header py-10 justify-between flex flex-col md:flex-row">
        <div className="headline">
          <h1 className={globalStyles.h1}>{title()}</h1>
          <p className="mb-4">RESHARE WAREHOUSE MANAGED SOFTWARE </p>
        </div>
        <SearchBox
          setSearchValueInput={setSearchValueInput}
          searchValueInput={searchValueInput}
          searchValue={searchValue}
          handleSearch={handleSearch}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default PageSection;
