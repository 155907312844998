import { useState } from "react";
import { useDispatch } from "react-redux";
import AddRackPanel from "../../../components/AddLinePanel/AddLinePanel";
import Option from "../../../components/Option";
import { ILine } from "../../../interface/data";
import { addLine } from "../../../store/reducers/warehouse";
import styles from "./styles.module.scss";

type propsType = {
  setLine?: any;
  setLines?: any;
  lines: any;
  getPartnerpartnerWarehouseGroups: any;
};

function RackList(props: propsType) {
  const { lines, setLine, setLines, getPartnerpartnerWarehouseGroups } = props;
  const lineStatus = {
    value: ["available", "allocated", "full"],
    content: ["Hoạt động", "Có hàng đặt trước", "Đã đầy"],
  };

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenPanel, setOpenPanel] = useState(false);

  const dispatch = useDispatch();

  const setUpdateRack = (newLine: ILine) => {
    dispatch(addLine(newLine));
  };

  const totalSlotsInShelf = (line: ILine) => {
    let total = line.fragments.map((frag) => {
      return frag.shelves.map((shelf) => {
        return shelf.capacity;
      });
    });
    return total.flat().reduce((partialSum, a) => partialSum + a, 0);
  };

  const totalFragmentInLine = (line: ILine) => {
    return line.fragments.length;
  };

  const totalShelfInLine = (line: ILine) => {
    let total = line.fragments.map((frag) => {
      return frag.shelves.length;
    });
    return total.flat().reduce((partialSum, a) => partialSum + a, 0);
  };

  return (
    <div className={styles.container}>
      <div className="flex justify-between mb-8">
        <h2 className={styles.title}>Danh sách dãy</h2>
        <div className="filterSection flex gap-6 items-center">
          Filter <Option option={lineStatus} />
        </div>
      </div>
      <div className={styles.rackTableWrap}>
        <table>
          <thead>
            <tr>
              <th>Thứ tự</th>
              <th>ID</th>
              <th>SL Dãy</th>
              <th>SL Phân kệ</th>
              <th>SL Kệ con</th>
              <th>Tổng slot</th>
              <th>Sử dụng</th>
              <th>Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line: any, i: any) => {
              return (
                <tr
                  key={i}
                  onClick={() => setLine && setLine(line.code)}
                  className={styles.row}
                >
                  <td className="align-center">{i + 1}</td>
                  <td>{line.code}</td>
                  <td>1</td>
                  <td>{totalFragmentInLine(line)}</td>
                  <td>{totalShelfInLine(line)}</td>
                  <td>{totalSlotsInShelf(line)}</td>
                  <td>Chưa tích hợp</td>
                  {line.deleteAt === null ? (
                    <td>Đang sử dụng</td>
                  ) : (
                    <td>Đã xóa</td>
                  )}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                onClick={() => setOpenPanel(true)}
                colSpan={8}
                className={styles.addLine}
              >
                + Thêm sào mới
              </td>
            </tr>
          </tfoot>
        </table>
        {isOpenPanel && (
          <AddRackPanel
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setLines={setLines}
            getPartnerpartnerWarehouseGroups={getPartnerpartnerWarehouseGroups}
            lines={lines}
            setClose={setOpenPanel}
          />
        )}
      </div>
    </div>
  );
}

export default RackList;
